import React, { useState } from 'react';
import ProductBox from '../../../components/general/productBox';
import MultiProductsBox from '../../../components/general/multiProductsBox';
import { useKeenSlider } from 'keen-slider/react';
import Arrow from './tab-arrow';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import CustomButton from '../../../components/general/button';


const HomePageTabContent = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const { products, currency, productsOnMobile, showDots = true, showArrows = true, showMultiProductsOnRow = true, viewAppealsLink, isPalestineRefugee = false, arabic = false, lang = 'en' } = props;
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "snap",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    slides: {
      origin: "auto",
      perView: 1,
      spacing: 8,
    },
  })
  // console.log("arabic=",arabic)
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className='hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4 overflow-hidden'>
        {products && products.map((item, index) => (
          <div key={`products__${index}`} className="w-full basis-1/3 lg:basis-1/4 max-w-[300px]">
            {item.products
              ? <MultiProductsBox
                multiProducts={item.products}
                staticLink={'/'}
                title={item.title}
                description={item.description}
                image={item.img}
                arabic={arabic}
                lang={lang}
                alt={item?.alt}
                currency={currency ? currency : 'AUD'}
                showMultiProductsOnRow={showMultiProductsOnRow}
              />
              : <ProductBox
                stateVariableObj={{ productName: item.name }}
                product={item}
                arabic={arabic}
                lang={lang}
                // linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : '!font-normal'}
                linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                isStaticProduct={item.isStaticProduct}
                staticLink={item.staticLink}
                staticLinkText={item.staticLinkText}
                currency={currency ? currency : 'AUD'}
                titleClasses={`uppercase`}
                isPalestineRefugee={isPalestineRefugee}
              />
            }
          </div>
        ))}
        {viewAppealsLink && <div className="flex w-full justify-center items-center py-2 md:py-4">
          <CustomButton
            link={viewAppealsLink}
            title={arabic || lang === 'ar' ? 'عرض كافة الاستئنافات' : lang === 'fr' ? `VOIR TOUS LES APPELS` : 'View all Appeals'}
            className={arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}
          />
        </div>}
      </div>
      <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
        {showArrows && products.length > 1 && <>
          <div className='absolute translate-y-1/2 h-[24px] top-[47%] -left-[15px] z-10'
            onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
          >
            <LiaAngleLeftSolid color='white' size={30} />
          </div>
          <div className='absolute translate-y-1/2 h-[24px] top-[47%] -right-[15px] z-10'
            onClick={e => e.stopPropagation() || instanceRef.current?.next()}
          >
            <LiaAngleRightSolid color='white' size={30} />
          </div>
        </>}
        <div ref={sliderRef} className="keen-slider">
          {productsOnMobile
            ? <div className="keen-slider__slide basis-full flex justify-center">
              <div className="w-full max-w-[300px]">
                {productsOnMobile.products.length && <MultiProductsBox
                  multiProducts={productsOnMobile.products}
                  staticLink={'/'}
                  title={productsOnMobile.title}
                  description={productsOnMobile.description}
                  image={productsOnMobile.img}
                  alt={productsOnMobile?.alt}
                  arabic={arabic}
                  lang={lang}
                  currency={currency ? currency : 'AUD'}
                />}
              </div>
            </div>
            : products.map((item, index) => {
              return <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                <div className="w-full max-w-[300px]">
                  {item.products
                    ? <MultiProductsBox
                      multiProducts={item.products}
                      staticLink={'/'}
                      title={item.title}
                      description={item.description}
                      arabic={arabic}
                      lang={lang}
                      image={item.img}
                      alt={item?.alt}
                      currency={currency ? currency : 'AUD'}
                    />
                    : <ProductBox
                      stateVariableObj={{ productName: item.name }}
                      product={item}
                      arabic={arabic}
                      lang={lang}
                      // linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : '!font-normal'}
                      linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                      isStaticProduct={item.isStaticProduct}
                      staticLink={item.staticLink}
                      staticLinkText={item.staticLinkText}
                      currency={currency ? currency : 'AUD'}
                      titleClasses={`uppercase`}
                      isPalestineRefugee={isPalestineRefugee}
                    />
                  }
                </div>
              </div>
            })
          }
        </div>
        {showDots && products.length > 1 && <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
          {loaded && instanceRef.current && (
            <div className="flex justify-center items-center relative w-full">
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />
              {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                return (
                  <button
                    key={`q${idx}`}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                    }}
                    className={
                      'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                      (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                    }
                  ></button>
                );
              })}
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
              />
            </div>
          )}
        </div>}
        {viewAppealsLink && <div className="flex w-full justify-center items-center py-2 md:py-4 mt-2">
          <CustomButton
            link={viewAppealsLink}
            title={arabic ? 'عرض كافة الاستئنافات' : lang === 'fr' ? 'VOIR TOUS LES APPELS' : lang === 'ar' ? 'عرض كافة الاستئنافات' : 'View all Appeals'}
            className={arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}
          />
        </div>}
      </div>
    </>
  );
};
export default HomePageTabContent;