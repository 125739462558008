import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import CustomButton from '../../../components/general/button';
import Sticky from 'react-sticky-el';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import BangladeshTabContent from './bangladesh-tab-content';
import CountriesCrisisTabContent from './countries-crisis-tab-content';

const CountriesCrisisTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { foodAidProducts, medicalAidProducts, orphansSupportProducts, whereMostNeededProducts, lang = 'en', arabic = false } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);

  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  // const contentRef = useRef(null);
  // const tabsContainerRef = useRef(null);
  const [scrollLeftEnd, setScrollLeftEnd] = useState(true);

  const [activeTabProducts, setActiveTabProducts] = useState(0);

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  const tabButtonsProducts = [
    {
      tabTitle: lang === 'fr' ? 'Là où on en a le plus besoin' : lang === 'ar' ? 'حيث الحاجة الأكثر' : 'Where Most Needed',
    }
  ];

  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    // window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
    setActiveTabProducts(index);

    // const tabsContainer = tabsContainerRef.current;
    // const targetTab = tabsContainer.children[index];

    // Calculate the scroll offset to center the tab
    // const scrollLeft = targetTab.offsetLeft + targetTab.clientWidth / 2 - tabsContainer.clientWidth / 2;
    // tabsContainer.scroll({
    //   left: scrollLeft,
    //   behavior: 'smooth',
    // });
  };

  useEffect(() => {
    // const tabsContainer = tabsContainerRef.current;
    // if (tabsContainer) {
    //   tabsContainer.scrollLeft = 0; // Ensure the scroll is at the start
    // }

    // const handleScroll = () => {
    //   const scrollX = tabsContainer.scrollLeft;

    //   // Calculate the maximum scrollable width
    //   const maxScrollLeft = tabsContainer.scrollWidth - tabsContainer.clientWidth;

    //   // Check if scrolled to the rightmost part
    //   if (scrollX === maxScrollLeft) {
    //     setScrollLeftEnd(false);
    //     console.log('Scrolled to right edge.');
    //   } else if (scrollX === 0) {
    //     setScrollLeftEnd(true);
    //     console.log('Scrolled to left edge.');
    //   } else {
    //     setScrollLeftEnd(false);
    //   }
    // };

    // tabsContainer.addEventListener('scroll', handleScroll);

    // return () => {
    //   tabsContainer.removeEventListener('scroll', handleScroll);
    // };
  }, []);
  return (
    <section className="bg-[#fff]" dir={lang === 'ar' ? 'rtl' : 'ltr'}>

      <div className={`w-full mx-auto px-4 md:px-0 py-4 md:py-12 bg-[#253B7E]`}>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 md:mt-10">
          <div className="flex flex-col w-full justify-center">
            {/* {console.log("on tabs=",activeTabProducts, contentRef, arabic,lang, whereMostNeededProducts, selectedCurrency)} */}
            {/* {activeTabProducts === 0 && (
              <div ref={contentRef}>
                <BangladeshTabContent
                  arabic={arabic}
                  lang={lang}
                  products={whereMostNeededProducts}
                  currency={selectedCurrency || 'AUD'}
                  showDots={false}
                  showArrows={false}
                  showMultiProductsOnRow={false}
                  viewAppealsLink={null}
                />
              </div>
            )} */}
                            <CountriesCrisisTabContent
                  arabic={arabic}
                  lang={lang}
                  products={whereMostNeededProducts}
                  currency={selectedCurrency || 'AUD'}
                  showDots={false}
                  showArrows={false}
                  showMultiProductsOnRow={false}
                  viewAppealsLink={null}
                />
          </div>
        </div>
      </div>
    </section>
  );
};
export default CountriesCrisisTabs;