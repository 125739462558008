import { useEffect, useState } from 'react';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react'
import { useNavigate, useParams } from 'react-router-dom';
// import video from '../../videos/What Relief is MATW Providing in Palestine.mp4';
import "keen-slider/keen-slider.min.css";
// import ZakatWidget from '../zakat/zakatWidget';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
// import medicalKitsImg from '../../images/landings/winter/medical-kits.png';
// import fuelImg from '../../images/landings/winter/fuel.png';
// import mattressesImg from '../../images/landings/winter/mattresses.png';
// import medicalReliefImg from '../../images/landings/winter/medical-relief.png';
// import hotMealsImg from '../../images/landings/winter/hot-meals.png';
// import blanketImg from '../../images/landings/winter/blankets.png';
// import medicalBedsImg from '../../images/landings/winter/medical-beds.png';
import { winterFaqs, faqs } from './faq';
import Accordion from '../../components/general/accordion';
// import donationPolicyImg from '../../images/landings/winter/donation-policy.png';
// import CrisisWidget from '../crisis-and-emergencies/crisisWidget';
import { selectProducts } from '../../store/products';
// import CrisisWidget from '../crisis-and-emergencies/crisisWidget';
// import { selectProducts } from '../../store/products';
// import OrphanWidget from '../orphans/orphanWidget';
import ClothingImg from '../../images/landings/winter/clothing.png';
import ShelterImg from '../../images/landings/winter/shelter.png';
import FoodAidImg from '../../images/landings/winter/foodAid.png';
import WaterImg from '../../images/landings/winter/water.png';
import MilkImg from '../../images/landings/winter/milk.png';
import BreadImg from '../../images/landings/winter/bread.png';
import SupportImg from '../../images/landings/winter/support.png';
import FoodPackImg from '../../images/landings/winter/foodPack.png';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { generateLinkWithLang } from '../../utils/linkGenerator';



const impactNumbers = [
    {
        total: '700,751',
        lastUpdate: 'November 2022 - February 2023'
    },
    {
        title: 'Food Aid',
        amount: '35,480',
        image: FoodAidImg
    },
    {
        title: 'Clothing',
        amount: '12,221',
        image: ClothingImg
    },
    {
        title: 'Shelter',
        amount: '5,450',
        image: ShelterImg
    },
    {
        title: 'Food Packs',
        amount: '593,300',
        image: FoodPackImg
    },
    {
        title: 'Water',
        amount: '2,000',
        image: WaterImg
    },
    {
        title: 'Baby Milk',
        amount: '15,000',
        image: MilkImg
    },
    {
        title: 'Bread',
        amount: '700',
        image: BreadImg
    },
    {
        title: 'Orphan Support',
        amount: '36,600',
        image: SupportImg
    }

];
const WinterLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const navigate = useNavigate();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [winterProducts, setWinterProducts] = useState([0, 0, 0]);
    const [fundraisedProducts, setFundraisedProducts] = useState([0, 0, 0]);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideFundraised, setCurrentSlideFundraised] = useState(0);
    const [currentSlideImpact, setCurrentSlideImpact] = useState(0);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const products = useSelector((state) => selectProducts(state));
    // console.log("products=", products);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [sliderRefFundraised, instanceRefFundraised] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideFundraised(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const [sliderRefImpact, instanceRefImpact] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImpact(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    // const impactInfo = [
    //     {
    //         total: '529,833',
    //         lastUpdate: '15th November 2023'
    //     },
    //     {
    //         title: 'Medical Kits',
    //         amount: '8,000',
    //         image: medicalKitsImg
    //     },
    //     {
    //         title: 'Fuel',
    //         amount: '3,333',
    //         image: fuelImg
    //     },
    //     {
    //         title: 'Mattresses',
    //         amount: '3,450',
    //         image: mattressesImg
    //     },
    //     {
    //         title: 'Food Packs',
    //         amount: '490,000',
    //         image: foodPackImg
    //     },
    //     {
    //         title: 'Medical Relief',
    //         amount: '10,000',
    //         image: medicalReliefImg
    //     },
    //     {
    //         title: 'Hot Meals',
    //         amount: '1,600',
    //         image: hotMealsImg
    //     },
    //     {
    //         title: 'Blankets',
    //         amount: '2,300',
    //         image: blanketImg
    //     },
    //     {
    //         title: 'Medical Beds & Loading Stretchers',
    //         amount: '10,000',
    //         image: medicalBedsImg
    //     }
    // ]
    const images = [
        'img1',
        'img2',
        'img3',
        'img4',
        'img5',
        'img6'
    ]
    const { lang } = useParams(); // Get the language from the URL
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        let tempWinter = [];
        let tempFundraised = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item.name === 'Winter Campaign') {
                        if (inner_item_product.creator === 'EMR-WNT-2023-01') tempWinter[0] = (inner_item_product); //Keep an Orphan Warm This Winter
                        if (inner_item_product.creator === 'EMR-WNT-2023-02') tempWinter[1] = (inner_item_product); //feed a family this winter
                        if (inner_item_product.creator === 'EMR-WNT-2023-03') tempWinter[2] = (inner_item_product); //shelter a family and keep them warm
                        if (inner_item_product.creator === 'EMR-WNT-FND-2023-06') { //Gas Heating for Morocco
                            let temp = Object.assign({}, inner_item_product, {
                                goal: 200000,
                                raised: 0
                            })
                            tempFundraised.push(temp);
                        }
                        if (inner_item_product.creator === 'EMR-WNT-FND-2023-05') { //Afghanistan Earthquake Appeal
                            let temp = Object.assign({}, inner_item_product, {
                                goal: 500000,
                                raised: 0
                            })
                            tempFundraised.push(temp);
                        }
                        if (inner_item_product.creator === 'EMR-WNT-FND-2023-04') { //Rebuild a House In Palestine
                            let temp = Object.assign({}, inner_item_product, {
                                goal: 100000,
                                raised: 0
                            })
                            tempFundraised.push(temp);
                        }
                    };
                    if (inner_item.name === 'Caravan') {
                        if (inner_item_product.creator === 'EM-SYR-EQ-02-2023') tempWinter[3] = (inner_item_product); //Caravan
                    };
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
        tempWinter = tempWinter.filter(n => n)
        tempFundraised = tempFundraised.filter(n => n)
        setWinterProducts(tempWinter);
        getProductsReports(tempFundraised);
    };
    const getProductsReports = async (tempFutureProducts) => {
        try {
            const response = await services.orphanProductTransactions();
            response.data.length && response.data.map((item, i) => {
                tempFutureProducts.map(future_item => {
                    if (future_item.name === item.product_name) {
                        future_item.raised = item.total_sales
                    }
                    return future_item
                })
                return null;
            })
            // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
            //     tempFutureProducts.map(future_item => {
            //         if (future_item.name === keyName) {
            //             future_item.raised = response.data.data[keyName]
            //         }
            //         return future_item
            //     })
            //     return null;
            // })
            setFundraisedProducts(tempFutureProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='!bg-white' hideFooter={true}>
            {seo &&
                <Helmet>
                    <title>{`${seo['winter']['title']}`}</title>
                    <meta name="title" content={`${seo['winter']['title']}`} />
                </Helmet>
            }
            {/* /* --------------------------------- Banner --------------------------------- */}
            {/* <div className="w-full bg-[url('../public/images/landings/winter/winter.webp')] sm:bg-[url('../public/images/landings/winter/winter.webp')] bg-center sm:bg-center  xl:bg-right bg-cover md:py-8 max-[500px]:min-h-[220px] min-[501px]:min-h-[350px] sm:min-h-[250px] md:min-h-[330px] lg:min-h-[450px] xl:min-h-[500px] 2xl:min-h-[550px] min-[1640px]:min-h-[650px] min-[1880px]:min-h-[650px] px-4 md:px-0" /> */}
            <div className="w-full flex flex-wrap items-end p-4 md:p-0 md:items-stretch bg-[url('../public/images/landings/winter/winter.webp')] bg-right bg-cover max-[500px]:min-h-[150px] min-[501px]:min-h-[200px] md:min-h-[320px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[550px]">
</div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------------- Widget ----------------------------------- */}
            <div className='w-full px-4 xl:px-2 py-4 md:py-6 hidden- flex lg:flex flex-wrap justify-center items-center bg-[#F60362]'>
                {/* <h2 className="text-white text-xl md:text-2xl mb-2 md:mb-4 basis-full text-center">
                    Make a Donation
                </h2>
                <div className='basis-full lg:container lg:mx-auto flex justify-center items-center bg-[#DFEEF2] px-4 rounded-md'>
                    <CrisisWidget
                        defaultProduct={{
                            creator: "MKD-EMR-WNT-2023-01",
                            label: "Keep Orphans Warm Where Most Needed",
                            value: 343
                        }}
                    />
                </div> */}
                {/* /* ------------------------------- Widget ----------------------------------- */}
                <div className='w-full lg:container lg:mx-auto px-4 flex justify-center items-center bg-white rounded-md'>
                    <CrisisWidget
                        defaultProduct={{
                            creator: "MKD-EMR-WNT-2023-01",
                            label: "Keep Orphans Warm Where Most Needed",
                            value: 343
                        }}
                    />
                </div>
                {/* /* -------------------------------------------------------------------------- */}

                {/* /* -------------------------- Section One - Support ------------------------- */}
                {/* <div className="w-full flex flex-wrap justify-center md:container md:mx-auto p-4 md:p-8 mt-6 bg-white rounded-t-md">
                    <h1 className="w-full text-2xl md:text-5xl text-[#253B7E] mb-4 text-center font-medium">
                        Support an Orphan
                    </h1>
                    <p className='w-full text-center text-lg md:text-xl mt-4 mb-6 px-2 md:px-6 text-[#78716C]'>
                        Your donation will touch the lives of more than 6000 orphaned children across Yemen, Palestine, Syria, Bangladesh, Lebanon, Jordan, and Togo.
                        With your support, we can provide comprehensive care, encompassing spiritual guidance, nutritious meals, top-tier education, and essential healthcare.
                    </p>
                </div> */}
                {/* /* -------------------------------------------------------------------------- */}

                {/* /* ------------------------------- Widget ----------------------------------- */}
                {/* <div className='w-full lg:container lg:mx-auto p-4 flex justify-center items-center mb-4 md:mb-12 bg-white rounded-b-md'>
                    <OrphanWidget />
                </div> */}
                {/* /* -------------------------------------------------------------------------- */}
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------------- Widget on mobile ------------------------- */}
            <div className="basis-full flex lg:hidden flex-wrap justify-center bg-[#F60362] p-6">
                <h2 className="basis-full text-white text-center text-5xl my-3 xl:my-6 font-['Gotcha']">Warmth is life</h2>
                <p className="basis-full text-white text-lg md:text-xl text-center mb-8 xl:mb-12 lg:max-w-[320px] mx-auto">
                    Help protect orphans and vulnerable families in emergency zones. They need our help to survive the deadly season of winter.
                </p>
                {/* <ZakatWidget hasSuggestPrice className={'rounded-[0px] max-w-[100%] md:max-w-[380px] w-full'} defaultProduct={{
                    creator: "MKD-EMR-WNT-2023-01",
                    label: "Keep Orphans Warm Where Most Needed",
                    value: 343
                }} /> */}
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Products ------------------------------- */}
            <div className="w-full mt-5 flex pb-4 md:pb-16">
                <div className="md:container md:mx-auto flex flex-wrap w-full">
                    <div className="basis-full flex-row justify-center flex-wrap flex">
                        {winterProducts.map((item, index) => {
                            return <div key={`products_water${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                            </div>
                        })
                        }
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* -------------------------- Section One - Support ------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container md:mx-auto p-4 md:p-8 mt-3- bg-white rounded-t-md">
                    <h1 className="w-full text-2xl md:text-5xl text-[#253B7E] mb-4 text-center font-medium">
                        Support an Orphan
                    </h1>
                    <p className='w-full text-center text-lg md:text-xl mt-4 mb-6 px-2 md:px-6 text-[#78716C]'>
                        Your donation will touch the lives of more than 6000 orphaned children across Yemen, Palestine, Bangladesh, Lebanon, Jordan, and Togo.
                        With your support, we can provide comprehensive care, encompassing spiritual guidance, nutritious meals, top-tier education, and essential healthcare.
                    </p>
                </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Donation Policy ------------------------ */}
            <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                {/* <div className="basis-full flex justify-center items-center px-4 md:basis-1/6">
                    <img src={donationPolicyImg} alt="donation policy" />
                </div> */}
                <div className="basis-full px-4">
                    <p className="text-[#14A2DC] text-xl md:text-2xl text-center mb-4 font-bold">
                        Our promise to you:
                    </p>
                    <p className="text-[#14A2DC] text-lg md:text-xl">
                        Founded by Ali Banat in 2015, Muslims Around The World Project (MATW) is a global Islamic Charity working across 24 countries around the world.
                        Our 100% donation policy ensures that every single donation goes the extra mile in reaching those most in need.
                    </p>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------------- Video ------------------------------------ */}
            <div className="w-full xl:container md:mx-auto py-8 md:py-16 flex flex-wrap justify-center items-center">
                <div className="basis-full flex flex-wrap items-center relative">
                    <div className="basis-full h-[200px] sm:h-[300px] md:h-[600px] lg:h-[750px]">
                        <iframe className='video'
                            width={'100%'}
                            height={'100%'}
                            title={'youtube'}
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            src={`https://youtube.com/embed/c5CX-kTTFjc?playlist=c5CX-kTTFjc&controls=0&autoplay=1&mute=1&playsinline=1&loop=1`}
                        >
                        </iframe>
                    </div>
                    <div className="basis-full flex-wrap hidden lg:block justify-center lg:absolute lg:top-12- lg:top-[calc(50%-260px)] lg:translate-y-1/2 lg:right-12 bg-[#F60362] p-6 lg:rounded-lg">
                        <h2 className="basis-full text-white text-center text-2xl md:text-5xl my-3 xl:my-6 font-['Gotcha']">Warmth is life</h2>
                        <p className="basis-full text-white text-lg md:text-xl text-center mb-8 xl:mb-12 lg:max-w-[320px] mx-auto">
                            Help protect orphans and vulnerable families in emergency zones. They need our help to survive the deadly season of winter.
                        </p>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Bismillah ------------------------------ */}
            <div className="md:container md:mx-auto flex justify-center flex-wrap mt-5 px-4 md:px-0">
                {/* <h2 className="basis-full text-[#093686] text-center text-4xl md:text-5xl my-3 md:my-6 font-['Gotcha']">Bismillah</h2> */}
                <h2 className="basis-full md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-5xl">
                    <span className='text-[#F60362]'>Winter</span> could be the next child killer
                </h2>
                <p className="basis-full text-center- text-lg md:text-xl text-[#253A7E] my-3 md:my-6">
                    Winter is a silent killer. For millions of people worldwide, this deadly season has arrived. For families living in poverty,
                    winter will be their biggest threat to survival.
                    <br />
                    <br />
                    Especially in disaster and conflict zones, where millions have been forced from their homes and do not have adequate shelter or
                    clothing to protect them from the harsh conditions.
                    <br />
                    <br />
                    In Palestine, where the conflict continues and two thirds of the population have little or no food to eat, families will face the harsh
                    conditions of winter with limited or no access to electricity or fuel to keep them warm.
                    <br />
                    <br />
                    In countries such as Lebanon, Afghanistan, Libya, and Morocco, millions more men, women and children now begin to face the
                    struggles of the harsh cold weather, without shelter, nutritious meals, warm clothing or medical care.
                </p>
                <p className="basis-full text-3xl md:text-4xl text-[#F60362] my-2 md:my-4 font-['Gotcha']">
                    Between us and them - our winter is worlds apart.
                    <br />
                    <br />
                    Warmth is life. Help us ensure the survival of vulnerable families this winter.
                </p>
            </div>
            {/* /* -------------------------------------------------------------------------- */}


            {/* /* --------------------------------- Funds Products ------------------------- */}
            <div className="w-full mt-5 flex pb-8 md:pb-16 bg-[#F1F1F1]">
                <div className="md:container md:mx-auto mt-5 flex flex-wrap justify-center w-full pt-8 md:pt-16">
                    <h2 className="basis-full md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-5xl">
                        Let's unite to <span className='text-[#F60362]'>raise funds</span> for a brighter future.
                    </h2>
                    <p className="basis-full md:basis-3/4 text-center text-lg md:text-xl text-[#253A7E] my-4 md:my-8">
                        Donate whatever you can to help keep people warm this Winter. From emergencies to poverty-ridden areas, these funds will help
                        people in need with food, clothing and shelter during the harsh winter season.
                    </p>
                    <div className="basis-full flex-row justify-center flex-wrap hidden sm:flex">
                        {fundraisedProducts.map((item, index) => {
                            return <div key={`products_masjid${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                <FundraiserProductBox goal={item.goal} product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                {/* <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} /> */}
                            </div>
                        })
                        }
                    </div>
                    <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
                        <div ref={sliderRefFundraised} className="keen-slider">
                            {fundraisedProducts.map((item, index) => {
                                return <div key={`carousel_masjid${index}`} className={`keen-slider__slide basis-full flex justify-center px-10`}>
                                    <div className="flex max-w-[250px]">
                                        <FundraiserProductBox goal={item.goal} product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                        {/* <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} /> */}
                                    </div>
                                </div>
                            })}
                        </div>
                        {loaded && instanceRefFundraised.current && (
                            <>
                                <Arrow
                                    left
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefFundraised.current?.prev()
                                    }
                                    disabled={currentSlideFundraised === 0}
                                />

                                <Arrow
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefFundraised.current?.next()
                                    }
                                    disabled={
                                        currentSlideFundraised ===
                                        instanceRefFundraised.current.track.details.slides.length - 1
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="w-full sm:hidden flex justify-center">
                        {loaded && instanceRefFundraised.current && (
                            <div className="dots flex pt-3 pb-6">
                                {[
                                    ...Array(instanceRefFundraised.current.track.details.slides.length).keys(),
                                ].map((idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                instanceRefFundraised.current?.moveToIdx(idx)
                                            }}
                                            className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideFundraised === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                        ></button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Impacts -------------------------------- */}
            {/* <div className="md:container md:mx-auto flex- justify-center flex-wrap mt-12 hidden">
                <h2 className="basis-full md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-5xl">
                    MATW's <span className='text-[#F60362] uppercase'>Impact</span>
                </h2>
                <div className="basis-full flex justify-center mt-4 px-4 md:px-0">
                    <div className="bg-[#F60362] text-white  rounded-full px-6 py-2 text-xl md:text-2xl lg:text-3xl">
                        TOTAL IMPACT: <strong className='ml-1'>{impactInfo[0].total}</strong>
                    </div>
                </div>
                <div className="basis-full hidden sm:flex flex-wrap justify-center md:mt-16">
                    {impactInfo.map((item, index) => {
                        return index > 0 && <div key={`impact_box${index}`} className="sm:basis-1/3 md:basis-1/3 xl:basis-1/4 px-4 py-2">
                            <div className="flex flex-wrap justify-center border-2 border-[#F60362] rounded-xl">
                                <div className="flex items-center justify-center h-[130px] my-6 px-4">
                                    <img src={item.image} alt={item.title} className='h-full' />
                                </div>
                                <div className="bg-[#F60362] text-center text-white rounded-lg- basis-full py-2 text-xl md:text-2xl lg:text-3xl">
                                    {item.amount}
                                </div>
                                <p className="text-[#253A7E] text-lg text-center my-4">
                                    {item.title}
                                </p>
                            </div>
                        </div>
                    })
                    }
                </div>
                <div className="w-full sm:hidden flex-wrap justify-center items-center mt-8 relative">
                    <div ref={sliderRefImpact} className="keen-slider">
                        {impactInfo.map((item, index) => {
                            return index > 0 && <div key={`carousel_impact${index}`} className={`keen-slider__slide basis-full px-4 py-2 flex justify-center`}>
                                <div className="flex flex-wrap justify-center border-2 border-[#F60362] rounded-xl max-w-[240px]">
                                    <div className="flex items-center justify-center h-[90px] my-6 px-8">
                                        <img src={item.image} alt={item.title} className='h-full' />
                                    </div>
                                    <div className="bg-[#F60362] flex items-center justify-center text-white rounded-lg- basis-full py-2 text-3xl">
                                        {item.amount}
                                    </div>
                                    <p className="text-[#253A7E] text-lg text-center my-4">
                                        {item.title}
                                    </p>
                                </div>
                            </div>
                        })}
                    </div>
                    {loaded && instanceRefImpact.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.prev()
                                }
                                disabled={currentSlideImpact === 0}
                            />

                            <Arrow
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.next()
                                }
                                disabled={
                                    currentSlideImpact ===
                                    instanceRefImpact.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>
                <div className="w-full sm:hidden flex justify-center">
                    {loaded && instanceRefImpact.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                ...Array(instanceRefImpact.current.track.details.slides.length).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRefImpact.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImpact === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div> */}
            {/* /* -------------------------------------------------------------------------- */}


            {/* /* --------------------------Impact Numbers------------------------------------------------ */}

            <div className="md:container md:mx-auto  mt-12 px-4 md:px-0">
                <h2 className="basis-full mb-3 md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-6xl">
                    MATW's <span className='text-[#F60362]'>IMPACT</span>
                </h2>
                <div className=' flex flex-col  items-center text-center text-white font-brandingMedium'>
                    <div className='bg-[#F60362] w-[348px] text-[26px] sm:w-[675px] my-6  h-[80] rounded-full sm:text-[50px]'>
                        TOTAL IMPACT: <span className='font-bold'>{impactNumbers[0].total}</span>
                    </div>
                    <div className='text-[#78716C] hidden sm:block text-[25px]'>{impactNumbers[0].lastUpdate}</div>
                </div>

                <div className='hidden sm:flex gap-4 mt-6 mb-16 justify-center flex-wrap'>
                    {impactNumbers.map((item, index) => (

                        index > 0 && <div className='relative flex justify-center  p-3'>
                            <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                {item.amount}
                            </div>
                            <div className='flex flex-col justify-around items-center h-[365px] min-w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                <div><img src={item.image} alt={item.title} /></div>
                                <div className='text-[#253B7E] text-[24px] font-bold mt-20'> {item.title}</div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* -------------Impact numbers slider for mobile----------- */}


                <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                    <div ref={sliderRefImpact} className="keen-slider">
                        {impactNumbers.map((item, index) => {
                            return index > 0 && <div key={`carousel_impact${index}`} className={`keen-slider__slide basis-full px-4 py-2 flex justify-center`}>
                                <div className='relative flex justify-center  p-3'>
                                    <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                        {item.amount}
                                    </div>
                                    <div className='flex flex-col justify-around items-center h-[365px] min-w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                        <div><img src={item.image} alt={item.title} /></div>
                                        <div className='text-[#253B7E] text-[24px] font-bold mt-20'> {item.title}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {loaded && instanceRefImpact.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.prev()
                                }
                                disabled={currentSlideImpact === 0}
                            />

                            <Arrow
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.next()
                                }
                                disabled={
                                    currentSlideImpact ===
                                    instanceRefImpact.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>
                <div className="w-full sm:hidden flex justify-center">
                    {loaded && instanceRefImpact.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                ...Array(instanceRefImpact.current.track.details.slides.length).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRefImpact.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImpact === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>

            </div>

            {/* /* ---------------------------------- Spending Info ------------------------- */}
            <div className="flex justify-center items-center flex-wrap mt-5 py-8 md:py-16 bg-[url('../src/images/landings/winter/spending-info.jpg')] bg-center sm:bg-center bg-cover h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]" />
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Response ------------------------------- */}
            <div className="md:container md:mx-auto flex justify-center flex-wrap mt-12 px-4 md:px-0">
                <h2 className="basis-full md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-5xl">
                    MATW's <span className='text-[#F60362]'>Response</span>
                </h2>
                <p className="basis-full md:basis-2/3 text-lg md:text-xl text-[#253A7E] my-2 md:my-4">
                    With your support, our MATW team will deliver our biggest winter response ever.
                    <br />
                    <br />
                    With teams on the ground in Palestine, Lebanon, Morocco, Jordan, Libya, Afghanistan and many other affected parts of the world,
                    we will be providing urgent winter essentials to protect families.
                    <br />
                    <br />
                    From food packs to shelter, fuel, blankets, heaters and warm clothing - our team will ensure that vulnerable families have the essentials
                    they need to survive the cold.
                </p>
                <div className="basis-full md:basis-2/3 flex justify-center flex-wrap my-2 md:my-4">
                    <p className="basis-full text-lg md:text-xl text-[#F60362] my-2">
                        Your Sadaqah could be the key to their survival this winter.
                    </p>
                    <button
                        className='text-white flex items-center justify-center mt-4 text-lg md:text-2xl transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[52px] px-6 sm:px-8 py-4 rounded-md'
                        onClick={() => navigate(generateLinkWithLang('/fundraising', lang))}>
                        Donate Now
                    </button>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Images --------------------------------- */}
            <div className="md:container md:mx-auto flex justify-center flex-wrap mt-4 sm:mt-12">
                <div className="basis-full hidden sm:flex flex-wrap">
                    <div className="basis-full md:basis-1/3 md:pr-2 mb-4">
                        <div className="bg-[url('../src/images/landings/winter/img1.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px]" />
                    </div>
                    <div className="basis-full md:basis-1/3 md:px-2 mb-4">
                        <div className="bg-[url('../src/images/landings/winter/img2.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px] mb-2" />
                        <div className="bg-[url('../src/images/landings/winter/img3.jpg')] bg-center bg-cover sm:h-[300px] md:h-[114px] lg:h-[124px] xl:h-[134px] 2xl:h-[144px] mb-2" />
                        <div className="bg-[url('../src/images/landings/winter/img4.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px]" />
                    </div>
                    <div className="basis-full md:basis-1/3 md:pl-2 mb-4">
                        <div className="bg-[url('../src/images/landings/winter/img5.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px]" />
                    </div>
                    <div className="basis-full">
                        <div className="bg-[url('../src/images/landings/winter/img6.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px]" />
                    </div>
                </div>
                <div className="w-full sm:hidden flex-wrap justify-center items-center mt-8 relative">
                    <div ref={sliderRefImages} className="keen-slider">
                        {images.map((item, index) => {
                            return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full px-9 flex justify-center`}>
                                <div className={`bg-[url('../src/images/landings/winter/img${index + 1}.jpg')] bg-center bg-cover h-[300px] w-full`} />
                            </div>
                        })}
                    </div>
                    {loaded && instanceRefImages.current && (
                        <>
                            <Arrow
                                left
                                bg={'bg-[#253A7E]'}
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImages.current?.prev()
                                }
                                disabled={currentSlideImages === 0}
                            />

                            <Arrow
                                bg={'bg-[#253A7E]'}
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImages.current?.next()
                                }
                                disabled={
                                    currentSlideImages ===
                                    instanceRefImages.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>
                <div className="w-full sm:hidden flex justify-center">
                    {loaded && instanceRefImages.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRefImages.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Four - FAQ --------------------------- */}
            <div className='w-full hidden h-auto  flex- flex-col justify-center items-center py-16'>
                <h2 className="text-4xl md:text-5xl font-medium text-center my-8 text-[#253B7E] leading-8">
                    Orphanages donations
                    <br />
                    <strong className='text-[#f60362] mt-2'>FAQs</strong>
                </h2>
                <div className="md:container md:mx-auto flex flex-wrap justify-center px-2 md:px-0 mt-6">
                    <div className="basis-full md:basis-1/3 mb-4 px-0 md:px-3">
                        <h2 className="text-[#253B7E] text-xl md:text-3xl mb-4 md:mb-8 font-bold">
                            {winterFaqs[0].question}
                        </h2>
                        <p className="text-[#253B7E] text-lg md:text-xl">
                            {winterFaqs[0].answer}
                        </p>
                    </div>
                    <div className="basis-full md:basis-1/3 mb-4 px-0 md:px-3">
                        <h2 className="text-[#253B7E] text-xl md:text-3xl mb-4 md:mb-8 font-bold">
                            {winterFaqs[1].question}
                        </h2>
                        <p className="text-[#253B7E] text-lg md:text-xl">
                            {winterFaqs[1].answer}
                        </p>
                    </div>
                    <div className="basis-full md:basis-1/3 mb-4 px-0 md:px-3">
                        <h2 className="text-[#253B7E] text-xl md:text-3xl mb-4 md:mb-8 font-bold">
                            {winterFaqs[2].question}
                        </h2>
                        <p className="text-[#253B7E] text-lg md:text-xl">
                            {winterFaqs[2].answer}
                        </p>
                    </div>
                    {winterFaqs.map((item, index) => {
                        return index > 2 && <div className='bg-white rounded-md p-4 mt-2 basis-full shadow-md' key={`faq${index}`}>
                            <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                {item.answer}
                            </Accordion>
                        </div>;
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* -------------------------------FAQS---------------------------------------- */}

            <div className='w-full  h-auto  flex- flex-col justify-center items-center py-16'>
                <h2 className="text-4xl md:text-5xl font-medium text-center my-8 text-[#253B7E] leading-8">


                    <strong className='text-[#f60362] mt-2'>FAQs</strong>
                </h2>
                <div className="md:container md:mx-auto flex flex-wrap justify-center px-2 md:px-0 mt-6">
                    {faqs.map((item, index) =>
                    (<div className='bg-white rounded-md p-4 mt-2 basis-full shadow-md' key={`faq${index}`}>
                        <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                            {item.answers}
                        </Accordion>
                    </div>)
                    )}
                </div>
            </div>

        </Layout>
    );
}

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <div
            className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-[30px] h-[60px] absolute top-[calc(50%-60px)] translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
            onClick={props.onClick}
        >
            <svg
                className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"
                    } ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {props.left && (
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                )}
                {!props.left && (
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                )}
            </svg>
        </div>
    )
}

export default WinterLandingPage;