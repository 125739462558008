import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { getProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { generateLinkWithLang } from '../../utils/linkGenerator';

const RamadanDua = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Donate Now', 'Make A Donation'));
    const { lang } = useParams(); // Get the language from the URL
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'MKD-MN-001') {
                setProduct(item);
            }
        });

    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['ramadanDua']['title']}`}</title>
                    <meta name="title" content={`${seo['ramadanDua']['title']}`} />
                    <meta name="description" content={`${seo['ramadanDua']['description']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/general/our-promise-banner-mobile.jpg')] md:bg-[url('../src/images/general/our-promise-banner-head.jpg')]" >
                <div className='leading-[36px] md:hidden'>We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} >
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">

                        <div className="col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            <div>
                                <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                    The essential Ramadan Dua List
                                </h1>
                                <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-brandingMedium'>
                                    Ramadan, the ninth month of the Islamic lunar calendar, is a time of profound spiritual reflection, self-improvement, and heightened devotion for Muslims around
                                    the world. Central to this holy month is the practice of fasting from dawn until sunset, which is one of the Five Pillars of Islam. However, Ramadan is not just
                                    about abstaining from food and drink; it's also about nurturing spiritual growth, seeking forgiveness, and strengthening one's relationship with the Divine.
                                    One of the key elements facilitating this spiritual journey is the recitation of duas or supplications, which are heartfelt prayers made to Allah.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Essence of Ramadan Duas
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Duas during Ramadan hold a special significance as they are considered a means to invoke Allah's mercy, forgiveness, and blessings. These prayers are moments of
                                    intimate conversation with the Creator, where one pours out their heart's desires, seeks forgiveness for past transgressions, and asks for guidance and support.
                                    The beauty of these supplications lies in their sincerity and the personal connection they foster between the believer and Allah.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h3 className='text-[#253B7E] text-[20px] sm:text-[36px] font-bold'>
                                    Key Duas for Ramadan
                                </h3>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Ramadan duas, such as the "dua for fasting" and the "dua for breaking fast in ramadan." These duas mark the beginning and end of each day's fast, framing the act
                                    of fasting within a spiritual context that transcends mere physical abstention. The "dua for fasting" is recited at Suhoor (pre-dawn meal) to express the intention
                                    to fast for the sake of Allah, while the "dua for breaking fast" is recited at Iftar (meal to break the fast), thanking Allah for the strength to complete the fast
                                    and for the sustenance provided.
                                </p>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    As the crescent moon heralds the arrival of Ramadan, we prepare to embark on a spiritual journey marked by reflection, prayer, and fasting. This sacred month offers
                                    a unique opportunity to deepen one's faith, seek forgiveness, and connect with the divine. Among the many practices during this holy month, the recitation of duas
                                    (supplications) holds a special place in the hearts of the faithful. Here are five beautiful duas that you can incorporate into your Ramadan routine to enhance your spiritual experience:
                                </p>
                            </div>
                            <ul className='mt-10 space-y-8'>
                                <li>
                                    <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>Dua for Breaking the Fast (Iftar):</h4>
                                    <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                        <strong className='block text-gray-700 mb-2'>"O Allah, I fasted for You and I believe in You [and I put my trust in You] and I break my fast with Your sustenance."</strong>
                                        This dua, recited at the moment of breaking the fast, expresses gratitude to Allah for sustaining us through the day and for the nourishment that He provides.
                                    </p>
                                </li>
                                <li>
                                    <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>Dua for Laylat al-Qadr:</h4>
                                    <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                        <strong className='block text-gray-700 mb-2'>"O Allah, You are forgiving, and You love forgiveness, so forgive me."</strong>
                                        Laylat al-Qadr, the Night of Decree, is a significant night in Ramadan, believed to be better than a thousand months. This dua seeks Allah's forgiveness,
                                        emphasizing His merciful nature.
                                    </p>
                                </li>
                                <li>
                                    <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>Dua for Guidance and Piety:</h4>
                                    <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                        <strong className='block text-gray-700 mb-2'>"O Allah, guide me among those You have guided, pardon me among those You have pardoned, befriend me among those You have befriended, bless me in what You have given, and protect me from the evil of what You have decreed."</strong>
                                        This comprehensive dua asks for guidance, forgiveness, friendship, blessings, and protection from Allah, covering various aspects of a believer's life.
                                    </p>
                                </li>
                                <li>
                                    <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>Dua for Protection from Hellfire:</h4>
                                    <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                        <strong className='block text-gray-700 mb-2'>"O Allah, save me from the fire."</strong>
                                        A simple yet profound plea, this dua is a reminder of the hereafter and the importance of living a righteous life to avoid the punishment of the hellfire.
                                    </p>
                                </li>
                                <li>
                                    <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>Dua for Good in This World and the Hereafter:</h4>
                                    <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                        <strong className='block text-gray-700 mb-2'>"Our Lord, give us good in this world and good in the Hereafter, and save us from the punishment of the fire."</strong>
                                        This dua, derived from the Quran, encompasses a request for well-being in this life, success in the hereafter, and salvation from hellfire, highlighting the balance between worldly life and the eternal life to come.
                                    </p>
                                </li>
                            </ul>
                            <p className='mt-7 md:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                Ramadan is a month of profound spiritual significance, and the practice of reciting duas plays a pivotal role in the observance of this holy month.
                                As we navigate through Ramadan, let us remember the power of dua and the promise of Allah to respond to His servants when they call upon Him.
                                May this Ramadan be a time of spiritual renewal and a journey towards heightened devotion and righteousness.
                            </p>

                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' >
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/ali-banat', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/purpose', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default RamadanDua