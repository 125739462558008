import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/general/layout';
import { generateLinkWithLang } from '../../utils/linkGenerator';
// import Layout from '../components/general/layout';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { lang } = useParams(); // Get the language from the URL
  useEffect(()=>{
    navigate(generateLinkWithLang('/', lang))
    // window.location.href = 'https://donate.matwproject.org.uk/palestine';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <Layout>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 py-8">
          {/* <div className="bg-white col-start-1 lg:col-start-2 xl:col-start-3 col-span-12 lg:col-span-10 xl:col-span-8 rounded-md p-6 shadow-md">
            <h1>Not found</h1>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
