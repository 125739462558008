import medicalKitPhoto from '../../../images/landings/palestine/medical-kits.png';
// import fuelPhoto from '../../../images/landings/palestine/fuel.png';
// import mattressesPhoto from '../../../images/landings/palestine/mattress.png';
import foodPacksPhoto from '../../../images/landings/palestine/food-packs.png';
import medicalReliefPhoto from '../../../images/landings/palestine/medical-relief.png';
// import hotMealsPhoto from '../../../images/landings/palestine/hot-meals.png';
import medicalBlankets from '../../../images/landings/palestine/blankets.png';
// import medicalBedsStretchesPhoto from '../../../images/landings/palestine/medical-beds-stretchers.png';

// Impact Slider Data
export const impactSliderData = [
  {
    img: { src: medicalKitPhoto, alt: 'Palestine Charity, provided medical kits' },
    value: '852,820',
    title: 'Health and Hygiene',
    arabicTitle: 'النظافة و الصحة',
    frenchTitle: "La Santé & L'hygiène",
  },
  {
    img: { src: foodPacksPhoto, alt: 'Palestine Appeal provided food packs' },
    value: '58,372,326',
    title: 'Food, Nutrition & Water',
    arabicTitle: 'الغذاء والتغذية والمياه',
    frenchTitle: 'Alimentation, Nutrition & Eau',
  },
  {
    img: { src: medicalReliefPhoto, alt: 'Charity for Palestine provided medical relief' },
    value: '155,790',
    title: 'General Relief',
    arabicTitle: 'المساعدات العامة',
    frenchTitle: 'Aide générale',
  },
  {
    img: { src: medicalBlankets, alt: 'Palestine Donation with medical beds and stretchers' },
    value: '68,692',
    title: 'Shelter & Clothing',
    arabicTitle: 'المأوى والملابس',
    frenchTitle: 'Abris & Vêtements',
  }
];

// Impact Info
export const impactInfo = {
  blankets: '65,518',
  foodPacks: '43,951,550',
  fuel: '33,500 L',
  hotMeals: '986,500',
  mattresses: '4,200',
  medicalKits: '288,795',
  medicalAid: '167,065',
  total: '59,449,628',
  lastUpdate: '29th March 2024',
  hygieneKits: '139,800',
};

// Videos
export const videos = [
  {
    link: 'https://www.youtube.com/embed/0fAVJbwQM0k',
    title: '',
    // date: '4 March, 2024',

  },
  {
    link: 'https://www.youtube.com/embed/8_5TzWdQGhY',
    title: '',
    // date: '4 March, 2024',

  },
  {
    link: 'https://www.youtube.com/embed/WEhV7uaCidQ',
    title: '',
    // date: '4 March, 2024',
  },
];

export const palestineVideos = [
  {
    link: 'https://www.youtube.com/embed/Slvi1qGuksM',
    title: '',
    // date: '7th June, 2024',
  },
  {
    link: 'https://www.youtube.com/embed/FvuSSaduJBw',
    title: '',
    // date: '7th June, 2024',
  },
  {
    link: 'https://www.youtube.com/embed/H8ViNvtgJnE',
    title: '',
    // date: '7th June, 2024',
  },
];