import Policy from '../../images/landings/palestine/policy100.png';

const ourPromiseSection = ({ alt, arabic, childClasses, lang = 'en' }) => {
  const content = {
    heading: lang === 'fr' ? `NOTRE PROMESSE À VOUS : ` : lang === 'ar' ? `وعدنا لكم:` : 'Our Promise To You:',
    description:
      lang === 'fr'
        ? `Fondé par Ali Banat en 2016, la Muslims Around The World Project (MATW) est une organisation caritative islamique mondiale qui œuvre dans 30 pays du monde entier. Notre politique de dons à 100 % fait en sorte que chaque don atteint davantage de personnes qui en ont le plus besoin.`
        : lang === 'ar'
        ? `أسسها علي بنات في عام 2016 ، مشروع مسلمون حول العالم (MATW) هو مؤسسة خيرية إسلامية عالمية تعمل في 30 دولة حول العالم. تضمن سياسة التبرع بنسبة 100٪ أن كل تبرع يقطع شوطا إضافيا في الوصول إلى من هم في أمس الحاجة إليه .`
        : 'Founded by Ali Banat in 2016, Muslims Around The World Project (MATW) is a Global Islamic Charity working across 30 countries around the world. Our 100% donation policy ensures that every single donation goes the extra mile in reaching those most in need.',
  };
  return (
    <section
      className={`bg-[url('../public/images/landings/crisis/palestine/pattern.png')] ${childClasses ? childClasses : ''}`}
      dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}
    >
      <div className="max-w-[1052px] px-4 py-8 mx-auto flex justify-center">
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-4 md:gap-8">
          <div className="order-2">
            <div className="border-[#14A2DC] text-center sm:text-left border-b-2 mb-3 pb-1">
              <h4
                className={`text-center md:text-2xl uppercase text-[#253B7E] ${
                  arabic || lang === 'ar' ? 'font-[AlmariMedium] md:text-right font-bold' : 'font-brandingBold md:text-left'
                }`}
              >
                {content.heading}
              </h4>
            </div>
            <div className={`text-sm md:text-base text-[#253B7E] font-bold`}>{content.description}</div>
          </div>
          <div className="order-1 flex-[0_0_88px] md:flex-[0_0_117px]">
            <img src={Policy} className="w-[100px] sm:w-[130px]" alt={alt ? alt : 'policy'} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ourPromiseSection;
