import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { getProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { generateLinkWithLang } from '../../utils/linkGenerator';

const WhenIsEid = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Donate Now', 'Make A Donation'));
    const { lang } = useParams(); // Get the language from the URL
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'MKD-MN-001') {
                setProduct(item);
            }
        });

    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['whenIsEid2024']['title']}`}</title>
                    <meta name="title" content={`${seo['whenIsEid2024']['title']}`} />
                    <meta name="description" content={`${seo['whenIsEid2024']['description']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/general/our-promise-banner-mobile.jpg')] md:bg-[url('../src/images/general/our-promise-banner-head.jpg')]" >
                <div className='leading-[36px] md:hidden'>We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} >
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">

                        <div className="col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            <div>
                                <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                    When is Eid al-Fitr 2024? Your Ultimate Guide
                                </h1>
                                <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-brandingMedium'>
                                    As the Islamic calendar draws near to another spiritual climax, the anticipation for Eid al-Fitr 2024 grows. Expected to fall on or near April 9,
                                    this celebration marks a significant moment for Muslims around the globe. But what determines this date, and why does it hold such importance?
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h4 className='text-[#253B7E] text-[22px] sm:text-[36px] font-bold'>
                                    The Islamic Lunar Calendar: A Brief Overview
                                </h4>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Eid al-Fitr celebrates the conclusion of Ramadan, the ninth month in Islam's Hijri calendar, and ushers in Shawwal, the 10th month. This lunar calendar's
                                    months begin with the sighting of a new crescent, leading to months that can vary between 29 and 30 days. The sighting—or the calculated prediction of
                                    sightability—of the moon plays a crucial role in determining the exact date of Eid al-Fitr.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h4 className='text-[#253B7E] text-[22px] sm:text-[36px] font-bold'>
                                    Ramadan to Shawwal: The Journey
                                </h4>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    With Ramadan 2024 set to begin on the evening of March 11 (making March 12 the first day of fasting), the countdown to Eid al-Fitr starts.
                                    The Islamic day begins at sunset; thus, Taraweeh prayers on the night of March 11 signal the start of Ramadan. Depending on the moon,
                                    Eid al-Fitr 2024 could be celebrated on April 9 or 10, after either 29 or 30 days of fasting.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h4 className='text-[#253B7E] text-[22px] sm:text-[36px] font-bold'>
                                    The Significance of Moon Sighting
                                </h4>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    The practice of moon sighting versus calculations for determining Islamic dates leads to variations in when Ramadan and Eid al-Fitr are observed.
                                    This traditional method can result in different communities starting Ramadan and celebrating Eid al-Fitr on varying dates. Nonetheless,
                                    the communal spirit of fasting, prayer, and reflection unites Muslims worldwide during this period.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h4 className='text-[#253B7E] text-[22px] sm:text-[36px] font-bold'>
                                    Eid al-Fitr 2024: What to Expect
                                </h4>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Eid al-Fitr is not just a day to mark the end of fasting; it's a day for communal prayers, feasting, charity, and festivities.
                                    As families gather and communities come together, the spirit of Eid spreads joy and generosity. Whether on April 9 or 10,
                                    Eid al-Fitr 2024 promises to be a day of celebration, reflection, and renewal for Muslims around the world.
                                </p>
                            </div>

                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' >
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/ali-banat', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/purpose', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default WhenIsEid