import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Loader, OurPromiseSection } from '../../components/general';
import { buildWaterWellFaqs } from './faqs';
import services from '../../services';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import SadaqahWidget from './sadaqahWidget';
import ProductBox from '../../components/general/productBox';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react'
// Slider Images
import Process from '../../images/sadaqah/water-process.jpg';
import WaterSlider1 from '../../images/sadaqah/water-slider-1.jpg'
import WaterSlider2 from '../../images/sadaqah/water-slider-2.jpg'
import WaterSlider3 from '../../images/sadaqah/water-slider-3.jpg'
import WaterSlider4 from '../../images/sadaqah/water-slider-4.jpg'
import banner from '../../images/sadaqah/Build-Well-Generic-Hero-Banner-Desktop.jpg'
import bannerMobile from '../../images/sadaqah/Build-Well-Generic-Hero-Banner-Mobile.jpg'
const images = [
    WaterSlider1,
    WaterSlider2,
    WaterSlider3,
    WaterSlider4,
];

const BuildWaterWellUat = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [waterProducts, setWaterProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [loaded, setLoaded] = useState(false);
    const [futureProducts, setFutureProducts] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const products = useSelector((state) => selectProducts(state));
    const [isLoaded, setIsLoaded] = useState(false);

    /* ----------------------------- Slider variables ---------------------------- */
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        loop:true,
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,
        },
    },
        [
            slider => {
              let timeout;
              let mouseOver = false;
              function clearNextTimeout() {
                clearTimeout(timeout);
              }
              function nextTimeout() {
                clearTimeout(timeout);
                if (mouseOver) return;
                timeout = setTimeout(() => {
                  slider.next();
                }, 2000);
              }
              slider.on('created', () => {
                slider.container.addEventListener('mouseover', () => {
                  mouseOver = true;
                  clearNextTimeout();
                });
                slider.container.addEventListener('mouseout', () => {
                  mouseOver = false;
                  nextTimeout();
                });
                nextTimeout();
              });
              slider.on('dragStarted', clearNextTimeout);
              slider.on('animationEnded', nextTimeout);
              slider.on('updated', nextTimeout);
            },
          ]

    )
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempWaterProducts = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') { //Water Well
                        item = Object.assign({}, item, {
                            alt: 'Build a well'
                        });
                        tempWaterProducts[0] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'WWC-CON-ABW-23-01-028') { //Africa Basic Water Well
                        item = Object.assign({}, item, {
                            alt:'Build a well in Africa'
                        });
                        tempWaterProducts[1] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') { //Bangladesh Basic Water Well
                        item = Object.assign({}, item, {
                            alt: 'Build a water well in Bangladesh'
                        });
                        tempWaterProducts[2] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') { //India Basic Water Well
                        item = Object.assign({}, item, {
                            alt: 'Build a water well in India'
                        });
                        tempWaterProducts[3] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-028') tempWaterProducts[4] = inner_item_product; //HD Africa Basic Water Well
                    if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') tempWaterProducts[5] = inner_item_product; //H2 Hand Pump Water Well
                    if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempWaterProducts[6] = inner_item_product; //L1 Africa Water Well
                    if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempWaterProducts[7] = inner_item_product; //L2 Africa Water Well
                    if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') tempWaterProducts[8] = inner_item_product; //Pakistan Solar Powered Well
                    return inner_item_product
                })
                return inner_item
            })
            return item;
        })
        tempWaterProducts = tempWaterProducts.filter(n => n)
        setWaterProducts(tempWaterProducts)
    };
    const getFundraiserProducts = async () => {
        let tempFutureProducts = [];
        try {
            const response = await services.fundraiserProducts();
            response.data.map(item => {
                item.raised = 0;
                if (item.name === 'Yemen Solar Powered Well') {
                    item.goal = 40000;
                    tempFutureProducts.push(item)
                }
                return item;
            });

            getProductsReports(tempFutureProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getProductsReports = async (tempFutureProducts) => {
        try {
            const response = await services.orphanProductTransactions();
            response.data.length && response.data.map((item, i) => {
                tempFutureProducts.map(future_item => {
                    if (future_item.name === item.product_name) {
                        future_item.raised = item.total_sales
                    }
                    return future_item
                })
                return null;
            })
            // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
            //     tempFutureProducts.map(future_item => {
            //         if (future_item.name === keyName) {
            //             future_item.raised = response.data.data[keyName]
            //         }
            //         return future_item
            //     })
            //     return null;
            // })
            setFutureProducts(tempFutureProducts)
        } catch (error) {
            console.error('error========', error);
        }
    };

    /* -------------------------------------------------------------------------- */
   /* ------------------------------- Start hooks ------------------------------ */
   useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
   }, [selectedCurrencyStore])

    useEffect(() => {
        if (products && Object.keys(products).length && !isLoaded) {
            getAllProducts();
            getFundraiserProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='bg-white' hideFooter>
           {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['buildAWaterWell']['title']}`}</title>
                    <meta name="title" content={`${seo['sadaqahJariyah']['buildAWaterWell']['title']}`} />
                    <meta name="description" content={`${seo['sadaqahJariyah']['buildAWaterWell']['description']}`} />
                </Helmet>
            }
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="Build a well, Sadaqah Jariyah" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Build a water well" className='min-[501px]:hidden w-full' />
            </section>
            <section className='bg-[#DFEEF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation to Build a Well</div>
                    <div className='w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        {/* <CrisisWidget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={{
                                creator: "GD-MP015",
                                label: "General Masjid Donations",
                                value: 168
                            }}
                        /> */}
                        <SadaqahWidget
                            defaultProduct={{
                            creator: "MKD-WEL-SDQ-2023-01",
                            label: "Water Well",
                            value: 320
                            }}
                        />
                    </div>
                    <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                           creator: "MKD-WEL-SDQ-2023-01",
                           label: "Water Well",
                           value: 320
                        }} />
                    </div>
                </section>
            </section>
            <OurPromiseSection />
            <main>
                <div className='max-w-[1440px] mx-auto'>
                    <section className='xl:px-20 mb-8 sm:mb-16'>
                        <div className='relative overflow-hidden flex flex-wrap'>
                            <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                            md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                            right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                                <div className='leading-[50px] w-full text-lg px-8 text-white text-center font-brandingMedium'>The Prophet PBUH said:</div>
                                <p className='text-2xl font-brandingBold leading-8 px-10 text-white text-center'>
                                    “The best charity is giving water to drink.” 
                                </p>
                            </div>
                            <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                            <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
                                <iframe className='video pointer-events-none'
                                    width={'100%'}
                                    height={'100%'}
                                    title={'youtube'}
                                    frameBorder={0}

                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://www.youtube.com/embed/zm1NI7MeRNY?controls=0&autoplay=1&loop=1&mute=1&playlist=zm1NI7MeRNY`}

                                >
                                </iframe>
                            </div>

                        </div>
                    </section>
                </div>

                <section>
                    <div className='max-w-[1440px] mx-auto xl:px-10'>
                        <div className='flex justify-center'>
                            <div className='md:text-center font-brandingBold text-[#093686] leading-[30px] sm:leading-[60px] text-3xl md:text-6xl px-4 w-full sm:w-10/12'>
                                Give <span className='text-[#F60362] mr-2'>the ultimate Sadaqah Jariyah</span> by building a water well.
                            </div>
                        </div>
                        <div className='flex justify-center mt-10 md:mt-16'>
                            <div className='md:text-center font-brandingBold text-[#093686] leading-[30px] sm:leading-[60px] text-3xl md:text-6xl px-4 w-full sm:w-10/12'>
                                Last year, our water well program delivered an impact of 459,350! <br/> <span className='text-[#F60362] mr-2'>Help us reach more!</span>
                            </div>
                        </div>
                        <div className='px-4 md:px-6'>
                            <div className="space-y-10 mt-8 md:mt-16 mb-12 lg:mb-20">
                                <p className="text-lg md:text-2xl font-brandingMedium text-[#78716C]">
                                    When you build a Water Well with us at MATW, you’re giving an entire community access to safe and clean drinking water. This means cooking, bathing, cleaning, drinking, water for crops and so much more!
                                </p>
                                <p className="text-lg md:text-2xl font-brandingMedium text-[#78716C]">
                                    Think of everything we use water for, now imagine if we didn’t have access to it! Simply put, your donation will allow a village to thrive whilst building a beautiful Akhirah for you or a loved one.
                                </p>
                                <p className="text-lg md:text-2xl font-brandingMedium text-[#78716C]">
                                    One of Ali Banat’s very first projects was the construction of a water well in Togo. This was the beginning of his legacy - a Sadaqah Jariyah that Insha’Allah continues to earn rewards for him today.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* What Will */}
                    <div className="px-4 flex flex-col gap-5 justify-center items-center bg-[url('../src/images/sadaqah/water-well-sub-hero.jpg')] bg-cover h-[260px] aspect-auto">
                        <div className="text-white font-brandingBold text-[32px] md:text-[60px]">
                            What will yours be?
                        </div>
                        <button onClick={() => { window.scroll({ top:500, left: 0, behavior: 'smooth' })}} className="text-[#F60362] rounded-xl bg-white w-[190px] py-3 text-[18px] mt-3 font-bold">
                            Build a well today.
                        </button>
                    </div>
                    {/* Products */}
                    <div className="max-w-[1440px] mx-auto xl:px-10">
                        <div className="flex flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
                        {waterProducts.map((item, index) => {
                        return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                            <ProductBox product={item} alt={item.alt} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                        </div>
                    })
                    }
                        </div>
                    </div>
                </section>

                <div className='max-w-[1440px] mx-auto'>
                    <section className='xl:px-20 py-8'>
                        <div className='mb-12'>
                            <div className='flex justify-center pt-4'>
                                <h1 className='md:text-center font-brandingBold text-[#093686] leading-[30px] sm:leading-[60px] text-3xl md:text-6xl px-4 w-full sm:w-10/12'>
                                Build a well, Sadaqah Jariyah: <br/>
                                 <span className='text-[#F60362]'>Build a water well in your name or someone elses name</span>
                                </h1>
                            </div>
                            <div className="space-y-10 mt-8 md:mt-16 mb:12 lg:mb-20">
                                <p className="text-lg md:text-2xl font-brandingMedium text-[#78716C]">
                                Build a water well in your name or the name of a loved one with MATW, creating a continuous stream of Sadaqah Jariyah that benefits communities in need. At MATW, we take every single pound, penny, euro, and dollar seriously. We are honored to be the custodians of your Amanah, especially when it comes to your investment in the Akhirah.
                                </p>
                                <p className="text-lg md:text-2xl font-brandingMedium text-[#78716C]">
                                Once you process your donation and commit to building a water well, our team conducts thorough needs assessments to determine the best locations. We focus on areas where clean water is scarce, such as remote villages or communities affected by conflict or natural disasters. Our goal is to ensure that every water well we build brings life and hope to those who need it most.
                                </p>
                                <p className="text-lg md:text-2xl font-brandingMedium text-[#78716C]">
                                We offer different options for water wells based on the type and location, ensuring your donation has the maximum impact. All you need to do is make this beautiful intention, process your donation, and leave the rest to our dedicated team. Start your Sadaqah Jariyah journey today by building a water well that provides clean water and saves lives for generations to come.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                {/* Fund Raise Section */}
                <div className='max-w-[1440px] mx-auto'>
                    <section className='xl:px-20 py-8'>
                        <div className='mb-12'>
                            <div className='flex justify-center pt-4'>
                                <div className='md:text-center font-brandingBold text-[#093686] leading-[30px] sm:leading-[60px] text-3xl md:text-6xl px-4 w-full sm:w-10/12'>
                                    Give the <span className='text-[#F60362]'>gift of water</span>, to <br/> save lives.
                                </div>
                            </div>
                            <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
                            {futureProducts
                                    ? <div className="flex flex-wrap justify-center">{futureProducts.map((item, index) => {
                                        return <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden">
                                            <FundraiserProductBox goal={item.goal} subTitle='Orphan Education' product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                        </div>
                                    })}</div>
                                    : <Loader />
                                }
                            </div>
                        </div>
                    </section>
                </div>

                <div className='max-w-[1440px] mx-auto'>
                    <section className='px-4 xl:px-20 mt-10 bg-white'>
                        <div className='flex flex-col md:flex-row gap-x-10 flex-wrap sm:flex-nowrap'>
                            <div className="basis-full overflow-hidden sm:basis-1/2 rounded-lg"><img src={Process} alt="Process" className='h-full object-cover object-center'/></div>
                            <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                                <h1 className='text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold'>“What if I cant?”</h1>
                                <div className="space-y-6 mt-5 mb-5 md:mb-7">
                                    <p className="text-[#78716C] font-brandingMedium text-lg lg:text-2xl">
                                        We don’t just offer full project solutions for our Water Wells. We allow for contributions to our general Water Aid program which contribute to provide safe and clean drinking water in emergencies, conflict and natural disaster zones. . Any amount that you contribute to our Water Aid program will also go towards building bigger water infrastructures such as rainwater harvesting projects.
                                    </p>
                                    <p className="text-[#78716C] font-brandingMedium text-lg lg:text-2xl">
                                        Every amount, no matter how big or small goes a long way - especially in the eyes of Allah.
                                    </p>
                                </div>
                                <button onClick={() => { window.scroll({ top:500, left: 0, behavior: 'smooth' })}} className='bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px]  font-bold'>Donate Now</button>
                            </div>
                        </div>
                    </section>
                </div>

                {/* /* --------------------------------- Images --------------------------------- */}
                <div className='max-w-[1440px] mx-auto'>
                    <section className='bg-[#F8F8F8] mt-10 sm:mt-20 py-10'>
                        <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                                <div ref={sliderRefImages} className="keen-slider">
                                    {images.map((item, index) => {

                                        return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                            {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                            <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                        </div>
                                    })}
                                </div>

                            </div>
                            <div className="w-full  flex justify-center">
                                {loaded && instanceRefImages.current && (
                                    <div className="dots flex pt-3 pb-6">
                                        {[
                                            ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                        ].map((idx) => {
                                            return (
                                                <button
                                                    key={idx}
                                                    onClick={() => {
                                                        instanceRefImages.current?.moveToIdx(idx)
                                                    }}
                                                    className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                                ></button>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>

                {/* -------------------------------------FAQS--------------------------------- */}
                <div className='max-w-[1440px] mx-auto'>
                    <section className='px-4 xl:px-20 mt-10 bg-white'>
                        <h1 className='md:text-center font-brandingBold text-[#093686] leading-[30px] sm:leading-[60px] text-3xl md:text-6xl w-full'>
                            Build a well donations <span className='text-[#F60362]'> FAQ’s</span>
                        </h1>
                        <div className='grid grid-cols-3 gap-x-16 gap-y-8 mt-8 lg:mt-10'>
                            <div className='col-span-3 sm:col-span-1'>
                                <h1 className='text-[#253B7E] font-brandingBold text-lg md:text-2xl leading-7 mb-4'>{buildWaterWellFaqs[0].question}</h1>
                                <p className='text-base text-[#78716C] font-brandingMedium mt-3  '>
                                    {buildWaterWellFaqs[0].answer}
                                </p>
                            </div>
                            <div className='col-span-3 sm:col-span-1'>
                                <h1 className='text-[#253B7E] font-brandingBold text-lg md:text-2xl leading-7 mb-4'>{buildWaterWellFaqs[1].question}</h1>
                                <p className='text-base text-[#78716C] font-brandingMedium mt-3  '>
                                    {buildWaterWellFaqs[1].answer}
                                </p>
                            </div>
                            <div className='col-span-3 sm:col-span-1'>
                                <h1 className='text-[#253B7E] font-brandingBold text-lg md:text-2xl leading-7 mb-4'>{buildWaterWellFaqs[2].question}</h1>
                                <p className='text-base text-[#78716C] font-brandingMedium mt-3 '>
                                    {buildWaterWellFaqs[2].answer}
                                </p>
                            </div>
                        </div>
                        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                            {buildWaterWellFaqs.map((item, index) => {
                                return index > 2 && (
                                    <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                        <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true} className="!text-[#253B7E] !text-lg !font-brandingBold">
                                            {item.answer}
                                        </Accordion>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default BuildWaterWellUat;