import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKeenSlider } from "keen-slider/react";
import { selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import Accordionn from '../../components/general/accordion';
import OneBillionWidget from './oneBillionWidget';
import ShowCurrencies from '../../utils/showCurrencies';
import useHandleAddProduct from '../../utils/handleAddProduct';
import "keen-slider/keen-slider.min.css";
import { billionFaqs } from './faq';
import { selectProducts } from '../../store/products';

const OneBillionLegacyPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector((state) => selectProducts(state));
    const [currentCurrency, setCurrentCurrency] = useState();
    const [addProduct] = useHandleAddProduct();
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        breakpoints: {
            "(min-width: 500px)": {
                slides: { perView: 2, spacing: 16 },
            },
            "(min-width: 992px)": {
                slides: { perView: 3, spacing: 24 },
            },
            "(min-width: 1200px)": {
                slides: { perView: 4, spacing: 32 },
            },
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 16,

        },
    })
    const carouselItems = [
        {
            id: 1,
            title: 'We recruit our own chefs and staff',
            image: 'carousel1.jpg',
            link: '/'
        },
        {
            id: 2,
            title: 'We manage the food material procurement',
            image: 'carousel2.jpg',
            link: '/'
        },
        {
            id: 3,
            title: 'We manage our own kitchens',
            image: 'carousel3.jpg',
            link: '/'
        },
        {
            id: 4,
            title: 'We maintain the highest food quality standards',
            image: 'carousel4.jpg',
            link: '/'
        },
        {
            id: 5,
            title: 'We recruit our own chefs and staff',
            image: 'carousel1.jpg',
            link: '/'
        },
        {
            id: 6,
            title: 'We manage the food material procurement',
            image: 'carousel2.jpg',
            link: '/'
        },
    ]
    const [foodAidProduct, setFoodAidProduct] = useState();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item.name === 'Make A Donation') {
                        if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') setFoodAidProduct(inner_item_product) // Food Aid
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    const handleAddNewProduct = (productAmount, subscription) => {
        let tempProduct = foodAidProduct;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: productAmount,
            recurring: subscription,
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='!bg-white'>
            <div className="w-full flex flex-wrap bg-[url('../public/images/landings/one-billion/one-billion-mobile.jpg')] sm:bg-[url('../public/images/landings/one-billion/one-billion.jpg')] bg-center sm:bg-center bg-cover md:py-8 max-[500px]:min-h-[400px] min-[501px]:min-h-[450px] sm:min-h-[350px] lg:min-h-[400px] xl:min-h-[550px] 2xl:min-h-[700px] px-4 md:px-0">
                <div className="flex w-full justify-between items-center md:container md:mx-auto md:px-4">
                    <div className="hidden lg:flex">
                        <OneBillionWidget
                            hasSuggestPrice
                            defaultSubscription={'month'}
                            defaultProduct={{
                                creator: "FWD-ODM-001",
                                label: "1 Billion Legacy – Food Aid",
                                value: 292
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* /* ------------------------------- Widget on Mobile ---------------------------- */}
            <div className='w-full bg-[#F60362] p-4 flex justify-center items-center lg:hidden'>
                <OneBillionWidget
                    hasSuggestPrice
                    defaultSubscription={'month'}
                    defaultProduct={{
                        creator: "FWD-ODM-001",
                        label: "1 Billion Legacy – Food Aid",
                        value: 292
                    }}
                />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ---------------------- Section One - Percentage -------------------------- */}
            <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 bg-[#E2EFF3] mt-10 md:mt-20 lg:mt-28 rounded-lg">
                <div className="basis-full md:basis-1/2 flex md:border-r border-stone-400 px-4 pt-12 md:py-0 items-center">
                    <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] h-12 mb-0">
                        Help feed change with $1 and feed 1 billion people
                    </h1>
                </div>
                <div className="basis-full flex-col md:basis-1/2 md:px-4 flex justify-center py-4 md:py-16">
                    <div className="flex items-center mb-2">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-2'>
                            <path d="M11.1547 9.67871C10.7036 9.89417 10.3107 10.2147 10.0091 10.6134C9.70754 11.0122 9.50601 11.4774 9.42147 11.9702C9.33693 12.4629 9.37184 12.9687 9.52328 13.4452C9.67471 13.9216 9.93825 14.3548 10.2918 14.7083C10.6453 15.0618 11.0784 15.3254 11.5549 15.4768C12.0313 15.6282 12.5372 15.6632 13.0299 15.5786C13.5226 15.4941 13.9879 15.2925 14.3866 14.991C14.7854 14.6894 15.1059 14.2965 15.3214 13.8454" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.7292 6.10352C14.0313 5.86133 13.2813 5.72852 12.5001 5.72852C8.7605 5.72852 5.72925 8.75977 5.72925 12.4993C5.72925 16.2389 8.7605 19.2702 12.5001 19.2702C16.2397 19.2702 19.2709 16.2389 19.2709 12.4993C19.2709 11.7181 19.1386 10.9681 18.8959 10.2702" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.4834 3.35021C15.955 2.51616 14.2412 2.08037 12.5 2.08302C6.74692 2.08302 2.08337 6.74656 2.08337 12.4997C2.08337 18.2528 6.74692 22.9164 12.5 22.9164C18.2532 22.9164 22.9167 18.2528 22.9167 12.4997C22.9167 10.6945 22.4573 8.99656 21.65 7.51635M22.9167 2.08302L12.5 12.4997" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className='text-[25px]'>1,000,000,000 beneficiaries</p>
                    </div>
                    <div className="flex w-full h-2 bg-stone-600 rounded-lg mb-2">
                        <div className="w-[9%] bg-[#F60362] rounded-lg"></div>
                    </div>
                    <div className="flex w-full justify-between">
                        <p className='text-[25px]'>96,625,262 beneficiaries impacted</p>
                        <h2 className='text-[#F60362] text-2xl'>9.6%</h2>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------ Section Two - Together -------------------------- */}
            <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mb-8 md:mb-0 mt-10 md:mt-20 lg:mt-28">
                <div className="basis-full md:basis-6/12 flex justify-center items-center">
                    <img src={'/images/landings/one-billion/together.jpg'} alt="" className='w-full- max-h-[569px] rounded-lg' />
                </div>
                <div className="basis-full md:basis-5/12 flex flex-col justify-around items-start md:pl-8">
                    <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mb-2 md:mb-4 mt-4 md:mt-0">
                        Together, we’ve fed 96 million people. Help us feed 1 billion beneficiaries.
                    </h1>
                    <p className='text-lg md:text-xl'>
                        In the world we live in today, everything – including food – is accessible with just a click of a button. Yet more than <strong>828 million</strong> people across the world don’t have enough to eat, including children, who live on one meal a day and suffer from severe malnutrition.
                        <br />
                        <br />
                        Our commitment to them is that we will work tirelessly to ensure that hunger is a thing of the past, and poverty is eradicated.
                        <br />
                        <br />
                        For the price of one takeaway meal a month or the price of your daily cup of coffee, you can provide life-saving food to nourish those who suffer from malnutrition and feed those who sleep hungry.
                    </p>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------ Section Three - How to make  -------------------- */}
            <div className="w-full flex flex-wrap  mt-8 md:mt-16 md:mx-auto py-4 md:py-8 relative">
                <h1 className="text-xl text-center w-full font-medium md:text-4xl text-[#253B7E] mb-4 md:mb-6 mt-4 md:mt-0">
                    How  we make this happen
                </h1>
                <div className="relative w-full flex flex-wrap bg-[#E2EFF3] p-4 md:p-8">
                    <div ref={sliderRef} className="keen-slider">
                        {carouselItems.map((item, index) => {
                            return <div key={`carousel${index}`} className={`keen-slider__slide bg-white rounded-lg flex flex-col p-2 lg:p-4 pb-8`}>
                                {/* <Link to={item.link}> */}
                                <img
                                    className={`w-full h-full- object-center cursor-pointer object-cover rounded-md`}
                                    alt={item}
                                    src={`/images/landings/one-billion/${item.image}`}
                                />
                                <h3 className="text-xl text-[#00A3DA] text-center mt-4">
                                    {item.title}
                                </h3>
                                {/* </Link> */}
                            </div>
                        })}
                    </div>
                    {console.log("slides", instanceRef.current)}
                    {/* {loaded && instanceRef.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRef.current?.prev()
                                }
                                disabled={currentSlide === 0}
                            />

                            <Arrow
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRef.current?.next()
                                }
                                disabled={
                                    currentSlide ===
                                    instanceRef.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )} */}
                </div>
                <div className="flex justify-center w-full bg-[#E2EFF3]">
                    {loaded && instanceRef.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                // ...Array(instanceRef.current.track.details.slides.length).keys(),
                                ...Array(3).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRef.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlide === idx ? " active bg-[#F60362]" : " bg-white")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------ Section Four - Donations ------------------------------ */}
            <div className="w-full flex flex-wrap justify-center md:container px-4- md:px-0 md:mx-auto py-4 md:py-8">
                <h1 className="text-xl text-center w-full font-medium md:text-4xl text-[#253B7E] mb-4 md:mb-6 mt-4 md:mt-0">
                    Food Aid
                </h1>
                <div className="flex basis-1/3 md:basis-1/2 lg:basis-1/3 p-1 md:p-2">
                    <div className="flex bg-[#E2EFF3] rounded justify-start sm:justify-center flex-col items-center w-full py-3 sm:py-8">
                        <svg width="70" height="70" viewBox="0 0 70 70" className='w-[50px] sm:w-[70px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1366_1052)">
                                <path d="M35 15.5859C24.2951 15.5859 15.5859 24.2951 15.5859 35C15.5859 45.7049 24.2951 54.4141 35 54.4141C45.7049 54.4141 54.4141 45.7049 54.4141 35C54.4141 24.2951 45.7049 15.5859 35 15.5859ZM35 51.6797C25.8028 51.6797 18.3203 44.1972 18.3203 35C18.3203 25.8028 25.8028 18.3203 35 18.3203C44.1972 18.3203 51.6797 25.8028 51.6797 35C51.6797 44.1972 44.1972 51.6797 35 51.6797Z" fill="#78716C" />
                                <path d="M69.2742 0.159688C68.8275 -0.0773827 68.2864 -0.0488084 67.8673 0.234199C61.1555 4.7693 57.1484 12.3129 57.1484 20.4133V38.1529C57.1484 40.0556 57.8895 41.8444 59.2348 43.1899L61.7969 45.752V65.8984C61.7969 68.16 63.6368 70 65.8984 70C68.16 70 70 68.16 70 65.8984V1.36719C70 0.861465 69.7208 0.397031 69.2742 0.159688ZM67.2656 65.8984C67.2656 66.6523 66.6523 67.2656 65.8984 67.2656C65.1446 67.2656 64.5312 66.6523 64.5312 65.8984V45.1855C64.5312 44.823 64.3872 44.4753 64.1308 44.2188L61.1682 41.2563C60.3393 40.4273 59.8828 39.3252 59.8828 38.1529V20.4133C59.8828 14.127 62.6009 8.21885 67.2656 4.14217V65.8984Z" fill="#78716C" />
                                <path d="M31.8634 24.1573C31.567 23.463 30.7634 23.1404 30.0691 23.4367C25.4298 25.4172 22.4321 29.9561 22.4321 35.0001C22.4321 35.7551 23.0442 36.3673 23.7993 36.3673C24.5544 36.3673 25.1665 35.7551 25.1665 35.0001C25.1665 31.0532 27.5123 27.5014 31.1428 25.9516C31.8371 25.6552 32.1598 24.8519 31.8634 24.1573Z" fill="#78716C" />
                                <path d="M35 22.4321L34.9309 22.4323C34.1758 22.4373 33.5678 23.0535 33.5729 23.8086C33.578 24.5606 34.1891 25.1666 34.94 25.1666C34.943 25.1666 34.9462 25.1666 34.9493 25.1666L35 25.1665C35.7551 25.1665 36.3672 24.5544 36.3672 23.7993C36.3672 23.0442 35.7551 22.4321 35 22.4321Z" fill="#78716C" />
                                <path d="M53.9524 17.0187C51.5368 14.4735 48.6895 12.4708 45.4896 11.0665C42.1761 9.61232 38.6469 8.875 35 8.875C30.8328 8.875 26.8494 9.82738 23.1605 11.7059C22.4877 12.0485 22.2198 12.8717 22.5624 13.5446C22.9052 14.2173 23.7284 14.485 24.4013 14.1425C27.7023 12.4617 31.2682 11.6094 35 11.6094C41.4799 11.6094 47.5064 14.1988 51.969 18.901C52.2378 19.1843 52.599 19.327 52.9609 19.327C53.2989 19.327 53.6374 19.2025 53.9018 18.9514C54.4495 18.4316 54.4721 17.5663 53.9524 17.0187Z" fill="#78716C" />
                                <path d="M55.2796 49.2707C54.6872 48.8026 53.8275 48.9031 53.3593 49.4953C48.8895 55.1487 42.1978 58.3908 35 58.3908C30.4951 58.3908 26.1219 57.1078 22.3531 54.6805C21.7184 54.2716 20.8722 54.455 20.4634 55.0896C20.0546 55.7245 20.2378 56.5705 20.8726 56.9793C25.0838 59.6916 29.969 61.1251 35 61.1251C43.039 61.1251 50.5125 57.5043 55.5041 51.1911C55.9724 50.5989 55.872 49.7391 55.2796 49.2707Z" fill="#78716C" />
                                <path d="M18.588 51.6693L18.5679 51.6462C18.5607 51.6373 18.5534 51.6283 18.5459 51.6195C18.0596 51.0418 17.1972 50.9679 16.6195 51.4541C16.0419 51.9406 15.9678 52.8028 16.4542 53.3806L16.4675 53.3963L16.4682 53.3958C16.4827 53.4141 16.4977 53.4324 16.5133 53.4505C16.7837 53.7655 17.1663 53.9271 17.5513 53.9271C17.8665 53.9271 18.1833 53.8187 18.4412 53.5973C19.014 53.1054 19.0798 52.2423 18.588 51.6693Z" fill="#78716C" />
                                <path d="M16.9531 0C16.198 0 15.5859 0.61209 15.5859 1.36719V16.1328H13.1239C13.124 16.1252 13.125 16.1178 13.125 16.11V1.36719C13.125 0.61209 12.5129 0 11.7578 0C11.0027 0 10.3906 0.61209 10.3906 1.36719V16.11C10.3906 16.1176 10.3917 16.1252 10.3917 16.1328H7.92859C7.92873 16.1252 7.92969 16.1178 7.92969 16.11V1.36719C7.92969 0.61209 7.3176 0 6.5625 0C5.8074 0 5.19531 0.61209 5.19531 1.36719V16.11C5.19531 16.1176 5.19641 16.1252 5.19641 16.1328H2.73438V1.36719C2.73438 0.61209 2.12229 0 1.36719 0C0.61209 0 0 0.61209 0 1.36719V18.0469C0 21.4006 2.03492 24.4274 5.05859 25.7358V65.8984C5.05859 68.16 6.89855 70 9.16016 70C11.4218 70 13.2617 68.16 13.2617 65.8984V25.7358C16.2854 24.4274 18.3203 21.4006 18.3203 18.0469V1.36719C18.3203 0.61209 17.7082 0 16.9531 0ZM11.515 23.4637C10.9301 23.6327 10.5273 24.1682 10.5273 24.7771V65.8984C10.5273 66.6523 9.91402 67.2656 9.16016 67.2656C8.40629 67.2656 7.79297 66.6523 7.79297 65.8984V24.7771C7.79297 24.1682 7.39033 23.6327 6.80531 23.4637C4.67482 22.848 3.1165 21.0193 2.79617 18.8672H15.524C15.2038 21.0193 13.6455 22.848 11.515 23.4637Z" fill="#78716C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1366_1052">
                                    <rect width="70" height="70" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h3 className='text-sx md:text-xl text-[#78716C] mt-2 md:mt-4'>
                            Weekly
                        </h3>
                        <h3 className='text-sx md:text-xl text-[#78716C]'>
                            {foodAidProduct && ShowCurrencies(currentCurrency, foodAidProduct[currentCurrency ? currentCurrency.toLowerCase() : 'aud'] * 7, true)}
                        </h3>
                        <button className='text-white flex items-center justify-center mt-4 text-xs md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-2 sm:px-6 rounded-md'
                            onClick={() => handleAddNewProduct(7, 'week')}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
                <div className="flex basis-1/3 md:basis-1/2 lg:basis-1/3 p-1 md:p-2">
                    <div className="flex bg-[#E2EFF3] rounded justify-start sm:justify-center flex-col items-center w-full py-3 sm:py-8">
                        <svg width="70" height="70" viewBox="0 0 70 70" className='w-[50px] sm:w-[70px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1366_1077)">
                                <path d="M22.9651 13.0268L24.4159 11.576C23.2174 10.3778 23.217 8.43746 24.4159 7.23885C26.4162 5.23824 26.4164 2.00074 24.4159 0L22.9651 1.45086C24.1635 2.64906 24.1639 4.5891 22.9652 5.78785C20.9647 7.78832 20.9645 11.026 22.9651 13.0268Z" fill="#78716C" />
                                <path d="M46.9532 11.5761C45.7575 10.3802 45.7575 8.43445 46.9532 7.23885C48.9536 5.23824 48.9537 2.00074 46.9532 0L45.5024 1.45086C46.7009 2.64906 46.7013 4.58924 45.5024 5.78785C43.5067 7.78367 43.5067 11.0309 45.5024 13.0267L46.9532 11.5761Z" fill="#78716C" />
                                <path d="M35.6846 11.5761C34.4888 10.3802 34.4888 8.43445 35.6844 7.23885C37.6849 5.23838 37.6852 2.00074 35.6844 0L34.2338 1.45086C35.4322 2.6492 35.4326 4.58924 34.2337 5.78785C32.238 7.78367 32.238 11.0309 34.2337 13.0267L35.6846 11.5761Z" fill="#78716C" />
                                <path d="M58.4123 20.5225V14.6807H36.1487V16.7324H56.3605V18.8015H13.5576V16.7324H34.097V14.6807H11.5059V20.5225C11.5059 33.4546 22.0269 43.9757 34.959 43.9757C47.8911 43.9757 58.4123 33.4546 58.4123 20.5225ZM34.9591 41.9239C23.2689 41.9239 13.7394 32.502 13.5618 20.8532H56.3563C56.1787 32.502 46.6494 41.9239 34.9591 41.9239Z" fill="#78716C" />
                                <path d="M42.3194 24.8607C40.2339 23.0757 37.2067 23.0758 35.1213 24.8607L34.959 24.9994L34.797 24.8607C32.7114 23.0758 29.6844 23.0761 27.5987 24.8607C25.0164 27.0706 25.02 31.0641 27.5987 33.2713L34.959 39.5705L42.3194 33.2713C44.9019 31.0613 44.8984 27.0677 42.3194 24.8607ZM40.9853 31.7126L34.9592 36.87L28.933 31.7126C27.308 30.3219 27.3102 27.8085 28.933 26.4197C30.2457 25.2963 32.1507 25.2965 33.4631 26.4197L34.9593 27.7L36.4557 26.4197C37.7679 25.2963 39.6732 25.2963 40.9856 26.4197C42.6106 27.8105 42.6082 30.3237 40.9853 31.7126Z" fill="#78716C" />
                                <path d="M18.0565 42.7708C17.4378 41.4737 16.1097 40.6356 14.6728 40.6356C12.1524 40.6356 10.3517 43.0671 11.0872 45.4782L12.0036 48.4825L9.79619 45.5661L8.3158 29.5288C8.28914 26.6916 5.95781 24.4032 3.11009 24.4499C1.40835 24.4749 0.0238037 25.8935 0.0238037 27.6122V46.212C0.0238037 48.9737 0.856284 51.6325 2.43115 53.9012L9.34624 63.8629L9.86195 70.0001H25.9466L24.0834 55.4097L18.0565 42.7708ZM11.7485 67.9484L11.3447 63.1441L4.11634 52.7313C2.78115 50.8077 2.07527 48.5534 2.07527 46.212V27.6122C2.07527 27.0083 2.55296 26.5101 3.14003 26.5014C4.85257 26.4764 6.26419 27.8527 6.26419 29.5787L7.80693 46.3378L13.7408 54.1771L15.56 60.1406L17.5224 59.5419L17.334 58.9244L13.0498 44.8795C12.7169 43.7882 13.5318 42.6874 14.6729 42.6874C15.3233 42.6874 15.9246 43.0668 16.2046 43.654L22.0899 55.9959L23.6163 67.9484H11.7485Z" fill="#78716C" />
                                <path d="M66.89 24.4497C64.0144 24.411 61.7106 26.7098 61.6842 29.5285L60.2039 45.5659L57.9962 48.4825L58.9128 45.4781C59.6482 43.0674 57.8481 40.6356 55.3273 40.6356C53.8903 40.6356 52.5622 41.4736 51.9437 42.7707L45.9168 55.4097L44.0533 69.9999H60.1382L60.6539 63.8628L64.8619 57.801L63.1765 56.6308L58.6553 63.144L58.2514 67.9483H46.3837L47.9102 55.9958L53.7955 43.6538C54.0755 43.0667 54.6767 42.6873 55.3272 42.6873C56.468 42.6873 57.2831 43.7879 56.9502 44.8794L52.4773 59.5417L54.4397 60.1404L56.2589 54.177L62.1929 46.3377L63.731 29.6729L63.7354 29.5786C63.7354 27.8313 65.1748 26.4707 66.8597 26.5013C67.4467 26.5099 67.9243 27.0083 67.9243 27.6121V46.2119C67.9243 48.5535 67.2184 50.8078 65.8832 52.7312L64.3713 54.9093L66.0567 56.0792L67.5687 53.9012C69.1436 51.6327 69.976 48.9738 69.976 46.2119V27.612C69.9763 25.8933 68.5918 24.4747 66.89 24.4497Z" fill="#78716C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1366_1077">
                                    <rect width="70" height="70" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h3 className='text-sx md:text-xl text-[#78716C] mt-2 md:mt-4'>
                            Monthly
                        </h3>
                        <h3 className='text-sx md:text-xl text-[#78716C]'>
                            {foodAidProduct && ShowCurrencies(currentCurrency, foodAidProduct[currentCurrency ? currentCurrency.toLowerCase() : 'aud'] * 30, true)}
                        </h3>
                        <button className='text-white flex items-center justify-center mt-4 text-xs md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-2 sm:px-6 rounded-md'
                            onClick={() => handleAddNewProduct(30, 'month')}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
                <div className="flex basis-1/3 md:basis-1/2 lg:basis-1/3 p-1 md:p-2">
                    <div className="flex bg-[#E2EFF3] rounded justify-start sm:justify-center flex-col items-center w-full py-3 sm:py-8">
                        <svg width="70" height="70" viewBox="0 0 70 70" className='w-[50px] sm:w-[70px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1366_1007)">
                                <path d={`M67.0543 49.8032C65.4661 48.7806 63.5477 48.5172 61.7708 49.0415C62.5591 47.9272 63.2609 46.7531 63.8701 45.5263C64.1219 45.0191 63.9151
                                44.4037 63.4078 44.1519C62.9006 43.9001 62.2852 44.1069 62.0334 44.6141C60.8996 46.8972 59.4192 48.9837 57.6335 50.8157C57.6095 50.8403 57.5872
                                50.8659 57.5661 50.8923L47.0775 55.6962C46.8166 55.8156 46.5391 55.8761 46.2525 55.8761H41.1307C41.0041 54.4222 40.5215 53.0258 39.7163 51.7979C39.4056
                                51.3244 38.7699 51.1924 38.2964 51.5029C37.823 51.8133 37.6908 52.4491 38.0014 52.9227C38.7289 54.032 39.1135 55.321 39.1135 56.6506C39.1135
                                56.67 39.1109 56.6945 39.1058 56.7235C39.1051 56.7277 39.105 56.7321 39.1043 56.7363C39.0648 56.9227 38.8991 57.0631 38.7011 57.0631H26.9404C24.9863
                                57.0631 23.1491 57.824 21.7674 59.2059C21.367 59.6063 21.367 60.2556 21.7674 60.6559C21.9676 60.8562 22.2301 60.9563 22.4924 60.9563C22.7548 60.9563
                                23.0173 60.8562 23.2174 60.6559C24.2118 59.6615 25.534 59.1138 26.9404 59.1138H38.7011C39.5917 59.1138 40.371 58.6371 40.8037 57.927H46.2524C46.8356
                                57.927 47.4004 57.8038 47.9311 57.5609L61.757 51.2285C63.1189 50.6046 64.6844 50.7163 65.9441 51.5275C67.2038 52.3385 67.9533 53.7176 67.9492 55.2163C67.9485
                                55.4572 67.8027 55.6793 67.5775 55.7824L47.4719 64.991C46.9494 65.2302 46.3929 65.3516 45.818 65.3516H13.2653V55.4991C13.2653 54.9329 12.8062 54.4738
                                12.2399 54.4738C11.6736 54.4738 11.2145 54.9329 11.2145 55.4991V66.5813C11.2145 66.8088 11.0295 66.9938 10.8021 66.9938H2.46312C2.23576 66.9938
                                2.05078 66.8088 2.05078 66.5813V43.8545C2.05078 43.6272 2.23576 43.4422 2.46312 43.4422H10.8021C11.0295 43.4422 11.2145 43.6272 11.2145 43.8545V50.7793C11.2145
                                51.3456 11.6736 51.8047 12.2399 51.8047C12.8062 51.8047 13.2653 51.3456 13.2653 50.7793V45.0296H13.8843C17.0956 45.0296 20.0453 46.6508 21.7748 49.3663C21.963
                                49.6619 22.2893 49.8409 22.6397 49.8409H32.3032C33.1635 49.8409 34.0048 50.001 34.8039 50.317C34.9277 50.3658 35.0551 50.389 35.1806 50.389C35.589 50.389 35.9752
                                50.1432 36.1345 49.7403C36.3426 49.2136 36.0844 48.618 35.5578 48.4097C34.5177 47.9986 33.4225 47.7901 32.3032 47.7901H23.1857C21.8265 45.8704 19.9448 44.4499
                                17.7987 43.6678C16.7841 41.3835 16.1317 38.9748 15.8565 36.4887H64.4185C64.2749 37.7756 64.0304 39.0534 63.6862 40.2971C63.5351 40.8429 63.855 41.4078 64.4008
                                41.5589C64.4923 41.5843 64.5844 41.5963 64.6749 41.5963C65.1243 41.5963 65.5368 41.2986 65.6626 40.8443C66.073 39.362 66.353 37.8352 66.4997 36.2999C67.3576
                                35.9359 67.961 35.0851 67.961 34.0961V30.5414C67.961 29.2222 66.8876 28.1488 65.5684 28.1488H59.4881C60.3151 26.5949 61.0539 25.0543 61.6514 23.6303C63.1984
                                19.9431 63.7912 17.1531 63.4829 14.9663C65.8669 14.5374 67.5637 12.9982 67.5508 11.1537C67.5396 9.55111 66.2074 8.16574 64.2489 7.58646C64.8195 5.05539 64.0231
                                2.76152 62.1868 1.99125C60.3509 1.22125 58.1561 2.25976 56.7498 4.44029C54.9642 3.44894 53.0422 3.46904 51.8909 4.58398C50.555 5.87775 50.6481 8.15494 52.0189
                                10.1576C50.2435 11.4701 48.669 13.849 47.1226 17.5349C47.0107 17.8013 46.9003 18.0753 46.7904 18.3524C45.5968 16.6515 44.1867 15.2653 42.6439 14.2892C41.7184
                                13.7035 40.7649 13.2794 39.8032 13.0179L39.0493 9.18552C39.0141 9.00629 39.1031 8.82131 39.2708 8.72519C40.8985 7.79346 41.7024 5.87283 41.2259 4.05447C40.6943
                                2.02461 38.652 0.68367 36.5764 1.00236C35.8296 1.11693 35.1295 1.43056 34.5521 1.90922C34.1372 2.2532 33.5825 2.36244 33.068 2.20125C32.3523 1.97689 31.5856
                                1.95187 30.851 2.12865C28.8095 2.62002 27.427 4.63429 27.7036 6.71447C27.9513 8.57767 29.4228 10.0507 31.2821 10.2966C31.4737 10.3219 31.6262 10.4593 31.6615
                                10.6386L32.417 14.4798C30.7692 15.7508 29.4625 17.638 28.6096 19.9234C27.5183 19.1801 26.2788 18.6759 24.9632 18.4475C24.8248 18.4235 24.704 18.3384 24.623
                                18.2079C23.5283 16.4416 21.3128 15.6697 19.3553 16.3724C17.3975 17.0753 16.1786 19.0799 16.4574 21.1391C16.4779 21.2913 16.4392 21.4333 16.3484 21.5392C14.7422
                                23.4085 13.9557 25.8034 14.1236 28.2242C13.0871 28.4865 12.3167 29.4243 12.3167 30.5413V34.096C12.3167 35.0847 12.9196 35.9352 13.7771 36.2993C13.9981 38.6271
                                14.5208 40.8955 15.3385 43.0711C14.8604 43.0104 14.3752 42.9786 13.8847 42.9786H13.1019C12.7478 42.052 11.852 41.3911 10.8026 41.3911H2.46312C1.10496 41.3911 0
                                42.4961 0 43.8543V66.581C0 67.9393 1.10496 69.0443 2.46312 69.0443H10.8021C11.8721 69.0443 12.7822 68.3574 13.1216 67.4023H45.8181C46.6899 67.4023 47.5337 67.2183
                                48.326 66.8554L68.4316 57.6469C69.3815 57.2118 69.9971 56.2598 70.0001 55.2219C70.006 53.0203 68.9049 50.9947 67.0543 49.8032ZM65.9102 30.5413V34.096C65.9102
                                34.2844 65.7568 34.4378 65.5684 34.4378H14.7867C14.7606 34.4365 14.7342 34.4369 14.7077 34.4376C14.5198 34.4369 14.3672 34.2839 14.3672 34.096V30.5413C14.3672
                                30.3529 14.5206 30.1995 14.709 30.1995H65.5684C65.7569 30.1995 65.9102 30.3527 65.9102 30.5413ZM53.3177 6.05685C53.9133 5.4799 55.2954 5.76879 56.4639
                                6.71447C56.7103 6.91394 57.0352 6.98818 57.3437 6.91558C57.6522 6.84299 57.91 6.63176 58.0416 6.34342C58.9284 4.40215 60.4907 3.50336 61.3934 3.88221C62.2962
                                4.26092 62.7494 6.00517 61.9857 7.99812C61.8722 8.29412 61.9021 8.62594 62.0665 8.89705C62.231 9.16803 62.5115 9.34781 62.8264 9.3839C64.3199 9.55508 65.4942
                                10.3386 65.4999 11.1679C65.5051 11.9159 64.4692 12.7726 62.8806 12.9845C61.9786 11.1947 60.3399 10.3033 58.9449 9.71805C57.5503 9.13289 55.7661 8.58847 53.8571
                                9.19865C52.892 7.91596 52.7733 6.58431 53.3177 6.05685ZM53.8364 11.4254C55.033 10.7872 56.3236 10.842 58.1517 11.609C59.9799 12.376 60.9232 13.2582 61.3062
                                14.5593C61.8334 16.35 61.3132 19.1348 59.7602 22.8368C59.0637 24.4971 58.1647 26.3266 57.1535 28.1487H45.9494C46.2499 26.8062 46.5996 25.4511 46.9864 24.1274C47.6168
                                24.5727 48.5512 25.1852 49.7614 25.8461C49.9174 25.9312 50.0858 25.9717 50.2519 25.9717C50.6149 25.9717 50.9666 25.7785 51.1528 25.4376C51.4242 24.9406 51.2412
                                24.3176 50.7443 24.0462C49.1699 23.1865 48.096 22.4121 47.6301 22.0562C48.0655 20.7372 48.5315 19.4773 49.0137 18.3282C49.0187 18.316 49.0239 18.3043 49.029
                                18.2922C50.0875 19.0486 52.1035 20.3471 54.9015 21.521C55.0311 21.5753 55.1655 21.6011 55.2977 21.6011C55.6984 21.6011 56.079 21.3647 56.2436 20.972C56.4628
                                20.4499 56.217 19.8489 55.6947 19.6299C52.745 18.3923 50.7097 16.9909 49.8974 16.3853C51.1785 13.8035 52.4995 12.1387 53.8364 11.4254ZM33.6736 10.2428C33.4683
                                9.19961 32.6152 8.40418 31.5509 8.26349C30.6083 8.13881 29.8622 7.39068 29.7365 6.44418C29.5965 5.39117 30.2969 4.37138 31.331 4.12256C31.7043 4.0326 32.0932
                                4.0449 32.455 4.15838C33.6351 4.5282 34.9084 4.27773 35.8609 3.48832C36.1527 3.24646 36.5077 3.08787 36.8877 3.02949C37.9392 2.86885 38.9735 3.54683 39.2428
                                4.57428C39.4848 5.49795 39.0776 6.47303 38.2525 6.94539C37.3208 7.47887 36.8326 8.53816 37.0378 9.58133L37.6529 12.7082C37.0769 12.6993 36.5032 12.7494 35.9358
                                12.8609C35.3649 12.9733 34.8156 13.146 34.2895 13.3725L33.6736 10.2428ZM36.3314 14.8729C39.7128 14.2078 43.4395 16.5154 45.9002 20.7675C45.0982 23.1098 44.3874
                                25.6608 43.8494 28.1486H29.6115C29.0392 21.4074 31.8717 15.7502 36.3314 14.8729ZM17.9037 22.8757C18.3747 22.3275 18.5883 21.5943 18.4896 20.8642C18.3399 19.7578
                                18.9953 18.6806 20.048 18.3028C21.1008 17.9249 22.2917 18.3393 22.8799 19.2882C23.268 19.9145 23.8995 20.3446 24.6125 20.4682C25.8572 20.6844 27.014 21.2173
                                27.9833 22.0153C27.5465 23.901 27.3865 25.9765 27.5532 28.1487H16.1744C16.0248 26.2417 16.6372 24.3497 17.9037 22.8757Z`} fill="#78716C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1366_1007">
                                    <rect width="70" height="70" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h3 className='text-sx md:text-xl text-[#78716C] mt-2 md:mt-4'>
                            Yearly
                        </h3>
                        <h3 className='text-sx md:text-xl text-[#78716C]'>
                            {foodAidProduct && ShowCurrencies(currentCurrency, foodAidProduct[currentCurrency ? currentCurrency.toLowerCase() : 'aud'] * 365, true)}
                        </h3>
                        <button className='text-white flex items-center justify-center mt-4 text-xs md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-2 sm:px-6 rounded-md'
                            onClick={() => handleAddNewProduct(365, 'year')}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------ Section Five - Harsh ---------------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container px-4- md:px-0 md:mx-auto py-4 md:py-8">
                <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mb-8 md:mb-0">
                    <div className="basis-full md:basis-1/2 flex justify-center items-center">
                        <img src={'/images/landings/one-billion/harsh.jpg'} alt="" className='w-full- max-h-[610px] rounded-lg' />
                    </div>
                    <div className="basis-full md:basis-5/12 flex flex-col justify-around items-start md:pl-8">
                        <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mb-2 md:mb-6 mt-4 md:mt-0">
                            The harsh realities of hunger
                        </h1>
                        <p className='text-lg md:text-xl'>
                            Imagine having to live day by day, worried about where your next meal is going to come from for you and your family? Imagine going to bed, hungry, every single night having barely eaten anything throughout the day. Imagine having to watch your children cry out from hunger. This is the reality for millions of people living in poverty all over the world.
                            <br />
                            <br />
                            Our <strong>1 Billion Beneficiary Legacy</strong> is a historic effort in to provide meals for those in need all over the world and symbolises that any act of kindness no matter the amount can have a huge impact. Knowing every dollar can feed one hungry person, with your help, we can feed <strong>1 billion</strong> people.
                            <br />
                            <br />
                            Since our inception, our MATW team has served meals to <strong>96 million</strong> people around the world. Help us take Ali Banat’s legacy to new heights by making this legacy your own. Together, we can feed <strong>1 billion</strong> people.
                        </p>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------ Section Six - Youtube --------------------------------- */}
            <div className="w-full px-4 md:px-0 py-4 md:py-8 bg-gradient-to-r from-[#00a3da] to-[#093484] flex mt-8 md:mt-16 md:min-h-[300px]">
                <div className="w-full flex flex-wrap justify-center items-center md:container md:mx-auto">
                    <div className="basis-full md:basis-1/2">
                        <h1 className="text-xl md:text-3xl text-white font-medium mb-4">“The best of you are those who feed others” – Prophet Muhammad SAW</h1>
                        <p className="text-lg md:text-xl font-light text-white">By taking action, we can make a difference and prevent further suffering, while standing by those who are most vulnerable and fulfilling the prophetic call for compassion and justice.</p>
                        {/* <p className="text-base text-[#95ceff]"></p> */}
                    </div>
                    <div className="basis-full md:basis-1/2 flex justify-end h-[150px] md:h-[200px] lg:h-[300px]">
                        <iframe className='video'
                            width={'100%'}
                            height={'100%'}
                            title='Gifted with Cancer - Ali Banat'
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}>
                        </iframe>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3] py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">Food aid donations FAQs</h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-8 md:px-0 mt-6">
                    {billionFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Eight - From the field --------------- */}
            <div className="w-full px-4 md:px-0 py-4 md:py-8 flex pt-8 md:pt-16 bg-[#E2EFF3]">
                <div className="w-full flex flex-wrap justify-center items-center md:container md:mx-auto">
                    <div className="basis-full">
                        <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">From the Field</h2>
                    </div>
                    <div className="basis-full flex flex-wrap py-4 md:py-8">
                        <div className="basis-full lg:basis-1/2 p-2 lg:p-4">
                            <iframe className='video'
                                width={'100%'}
                                height={'100%'}
                                title='Gifted with Cancer - Ali Banat'
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}>
                            </iframe>
                        </div>
                        <div className="basis-full flex flex-wrap lg:basis-1/2 p-1 lg:p-2">
                            <div className="basis-full lg:basis-1/2 p-1 lg:p-2">
                                <img
                                    className={`w-full h-full- object-center cursor-pointer object-cover rounded-md`}
                                    alt='carousel1'
                                    src={`/images/landings/one-billion/carousel1.jpg`}
                                />
                            </div>
                            <div className="basis-full lg:basis-1/2 p-1 lg:p-2">
                                <img
                                    className={`w-full h-full- object-center cursor-pointer object-cover rounded-md`}
                                    alt='carousel2'
                                    src={`/images/landings/one-billion/carousel2.jpg`}
                                />
                            </div>
                            <div className="basis-full lg:basis-1/2 p-1 lg:p-2">
                                <img
                                    className={`w-full h-full- object-center cursor-pointer object-cover rounded-md`}
                                    alt='carousel3'
                                    src={`/images/landings/one-billion/carousel3.jpg`}
                                />
                            </div>
                            <div className="basis-full lg:basis-1/2 p-1 lg:p-2">
                                <img
                                    className={`w-full h-full- object-center cursor-pointer object-cover rounded-md`}
                                    alt='carousel5'
                                    src={`/images/landings/one-billion/carousel5.jpg`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
        </Layout>
    );
}

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`w-[30px] h-[30px] absolute top-1/2 translate-y-1/2 fill-white cursor-pointer arrow ${props.left ? "arrow--left left-[5px]" : "arrow--right left-auto right-[5px]"
                } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}

export default OneBillionLegacyPage;
