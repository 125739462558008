/* eslint-disable react-hooks/exhaustive-deps */
import Palestinepreramadan1 from '../../images/landings/palestine-refugee/palestine-refugee-slider-1.jpg';
import Palestinepreramadan2 from '../../images/landings/palestine-refugee/palestine-refugee-slider-2.jpg';
import Palestinepreramadan3 from '../../images/landings/palestine-refugee/palestine-refugee-slider-3.jpg';
import Palestinepreramadan4 from '../../images/landings/palestine-refugee/palestine-refugee-slider-4.jpg';
import Palestinepreramadan5 from '../../images/landings/palestine-refugee/palestine-refugee-slider-5.jpg';
import Palestinepreramadan6 from '../../images/landings/palestine-refugee/palestine-refugee-slider-6.jpg';
import Palestinepreramadan7 from '../../images/landings/palestine-refugee/palestine-refugee-slider-7.jpg';
import Palestinepreramadan8 from '../../images/landings/palestine-refugee/palestine-refugee-slider-8.jpg';
import PalestineLimbs1 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-1.jpg';
import PalestineLimbs2 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-2.jpg';
import PalestineLimbs3 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-3.jpg';
import PalestineLimbs4 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-4.jpg';
import PalestineLimbs5 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-5.jpg';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import { selectLastOrders, selectProducts } from '../../store/products';
import AppealDetailsTabs from './components/appealDetailTabs';
import AppealLevelGiving from './components/appealLevelGiving';
import AppealDonors from './components/appealDonors';
import AppealImpact from './components/appealImpact';
import AppealWidget from './components/appealWidget';
import services from '../../services';
import Layout from '../../components/general/layout';
// import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
// import AppealVideos from './components/appealVideos';
import AppealCurrentProduct from './components/appealCurrentProduct';
// import { useHistory } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { CarouselImages } from '../../components/general/imagesCarousel';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
import CustomButton from '../../components/general/button';
import ShowCurrencies from '../../utils/showCurrencies';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CloseIcon } from '../../components/general/customIcons';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { generateLinkWithLang } from '../../utils/linkGenerator';
// import moment from 'moment';
// import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';

const MATWAppealDetailsLandingPage = ({ lang = 'en', campaign = 'palestine' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const impactRef = useRef(null);
  const donorsRef = useRef(null);
  const otherAppealsRef = useRef(null);
  const selectGivingRef = useRef(null);
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  // let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const products = useSelector(state => selectProducts(state));
  const orders = useSelector(state => selectLastOrders(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  // const [productTitle, setProductTitle] = useState('');
  const [givingProducts, setGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [currentgivingProducts, setCurrentGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0])
  const [goal] = useState(500000);
  const [currentProduct, setCurrentProduct] = useState();
  const [donors, setDonors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  // const [orders, setOrders] = useState([]);
  const [appealProducts, setAppealProducts] = useState([0, 0]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [impactReached, setImpactReached] = useState(false);
  const [levelGivingReached, setLevelGivingReached] = useState(false);
  const [donorsReached, setDonorsReached] = useState(false);
  const [otherAppealsReached, setOtherAppealsReached] = useState(false);
  // const [products, setProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [selectedGivingProducts, setSelectedGivingProducts] = useState({
    products: [],
    total: 0,
  });
  const [isMinimized, setIsMinimized] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const carouselImages = [
    { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
    { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
    { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
    { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
    { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan6, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan7, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan8, alt: 'donate for palestine emergency appeal' },
  ];
  const carouselImagesLimbs = [
    { src: PalestineLimbs1, alt: 'Limbs of hope' },
    { src: PalestineLimbs2, alt: 'Limbs of hope' },
    { src: PalestineLimbs3, alt: 'Limbs of hope' },
    { src: PalestineLimbs4, alt: 'Limbs of hope' },
    { src: PalestineLimbs5, alt: 'Limbs of hope' },
  ];
  const paramLang = useParams(); // Get the language from the URL
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = _prds => {
    // setIsLoaded(true);
    if (products && Object.keys(products).length) {
      let tempGivingProducts = [];
      let tempOtherAppealProducts = [];
      let tempCurrentProduct = null;
      let tempPalestineTotalRaised = 0;
      let tempPalestineTotalSupporters = 0;
      let tempEgyptianTotalRaised = 0;
      let tempEgyptianTotalSupporters = 0;
      let tempLimbsTotalRaised = 0;
      let tempLimbsTotalSupporters = 0;
      products.map(item => {
        item.sub_categories.map(inner_item => {
          if (inner_item.id === 65) { // Palestine Emergency
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'palestine',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product = Object.assign({}, inner_item_product, {
                    total_count: _prdItem.total_count,
                    total_sale: _prdItem.total_sale,
                  });
                  tempPalestineTotalRaised += _prdItem.total_sale;
                  tempPalestineTotalSupporters += _prdItem.total_supporters;
                }
                return _prdItem;
              });
              if (inner_item_product.creator === 'MA-PAL-GL-1-001') {
                if (campaign === 'palestine') {
                  tempCurrentProduct = inner_item_product
                } else {
                  inner_item_product.alternativeName = 'Gaza Can’t Wait!'
                  inner_item_product.short_description = 'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.'
                  tempOtherAppealProducts.push(inner_item_product);
                }
              }
              tempGivingProducts.push(inner_item_product);
              return inner_item_product;
            });
          }
          if (inner_item.id === 66) { // Palestine Refugee Resettlement
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'egyptian',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product = Object.assign({}, inner_item_product, {
                    total_count: _prdItem.total_count,
                    total_sale: _prdItem.total_sale,
                  });
                  tempEgyptianTotalRaised += _prdItem.total_sale;
                  tempEgyptianTotalSupporters += _prdItem.total_supporters;
                }
                return _prdItem;
              });
              if (inner_item_product.creator === 'MA-PAL-GL-3-001') {
                if (campaign === 'egyptian') {
                  tempCurrentProduct = inner_item_product
                } else {
                  inner_item_product.alternativeName = 'Help Rebuild Lives for Palestinians escaping conflict'
                  inner_item_product.short_description = 'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
                  tempOtherAppealProducts.push(inner_item_product);
                }
              }
              tempGivingProducts.push(inner_item_product);
              return inner_item_product;
            });
          }
          if (inner_item.id === 73) { // Limbs of hope
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 1000000,
                campaign: 'limbs-of-hope',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product = Object.assign({}, inner_item_product, {
                    total_count: _prdItem.total_count,
                    total_sale: _prdItem.total_sale,
                  });
                  // console.log("limbs=", _prdItem.total_sale, inner_item_product.name)
                  tempLimbsTotalRaised += _prdItem.total_sale;
                  tempLimbsTotalSupporters += _prdItem.total_supporters;
                }
                return _prdItem;
              });
              if (inner_item_product.creator === 'EMR-PAL-MASU-005') {
                if (campaign === 'limbs-of-hope') {
                  tempCurrentProduct = inner_item_product
                } else {
                  inner_item_product.alternativeName = 'GAZA: Limbs of Hope'
                  inner_item_product.short_description = 'Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need.'
                  tempOtherAppealProducts.push(inner_item_product)
                }
              }
              tempGivingProducts.push(inner_item_product);
              return inner_item_product;
            });
          }
          return inner_item;
        });
        return item;
      });
      // console.log('set-temp-OtherAppealProducts=', campaign, tempOtherAppealProducts, tempPalestineTotalRaised, tempEgyptianTotalRaised, tempPalestineTotalSupporters, tempEgyptianTotalSupporters);
      if (campaign === 'palestine') {
        tempCurrentProduct.totalRaised = tempPalestineTotalRaised;
        tempCurrentProduct.totalSupporters = tempPalestineTotalSupporters;
      } else if (campaign === 'egyptian') {
        tempCurrentProduct.totalRaised = tempEgyptianTotalRaised;
        tempCurrentProduct.totalSupporters = tempEgyptianTotalSupporters;
      } else {
        tempCurrentProduct.totalRaised = tempLimbsTotalRaised;
        tempCurrentProduct.totalSupporters = tempLimbsTotalSupporters;
      }
      tempOtherAppealProducts.map(item => {
        if (item.campaign === 'palestine') {
          item.totalRaised = tempPalestineTotalRaised;
          item.totalSupporters = tempPalestineTotalSupporters;
        } else if (item.campaign === 'egyptian') {
          item.totalRaised = tempEgyptianTotalRaised;
          item.totalSupporters = tempEgyptianTotalSupporters;
        } else {
          item.totalRaised = tempLimbsTotalRaised;
          item.totalSupporters = tempLimbsTotalSupporters;
        }
        return item;
      })
      // tempOtherAppealProducts[0].totalRaised = campaign === 'palestine' ? tempEgyptianTotalRaised : tempPalestineTotalRaised;
      // tempOtherAppealProducts[0].totalSupporters = campaign === 'palestine' ? tempEgyptianTotalSupporters : tempPalestineTotalSupporters;
      // console.log("tempOtherAppealProducts=", tempOtherAppealProducts)
      tempOtherAppealProducts = tempOtherAppealProducts.filter(n => n);
      tempGivingProducts = tempGivingProducts.filter(n => n);
      // console.log('set-tempGivingProducts=', tempGivingProducts, tempGivingProducts.length);
      tempGivingProducts = tempGivingProducts.sort((a, b) => a[selectedCurrencyStore.toLowerCase()] - b[selectedCurrencyStore.toLowerCase()])
      // tempCurrentProduct.totalRaised = campaign === 'palestine' ? tempPalestineTotalRaised : tempEgyptianTotalRaised;
      // tempCurrentProduct.totalSupporters = campaign === 'palestine' ? tempPalestineTotalSupporters : tempEgyptianTotalSupporters;
      // console.log('set-AppealProducts=', tempOtherAppealProducts, tempCurrentProduct);
      setGivingProducts(tempGivingProducts);
      setCurrentGivingProducts(tempGivingProducts)
      setAppealProducts(tempOtherAppealProducts);
      setCurrentProduct(tempCurrentProduct)
    }
  };

  const getDonors = async _currency => {
    // let pathName = window.location.pathname.split('/')[3].split("-").join(" ").toLowerCase();
    let req = {
      products: [],
      limit: 20,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      // end_date: endDateOfAppealCampaign,
      // start_date: new Date()
    };
    campaign === 'palestine'
      ? (req.products = [
        'MA-PAL-GL-1-001',
        'MA-PAL-GL-1-002',
        'MA-PAL-GL-1-003',
        'MA-PAL-GL-1-004',
        'MA-PAL-GL-1-005',
        'MA-PAL-GL-1-006',
        'MA-PAL-GL-1-007',
        'MA-PAL-GL-1-008',
        'MA-PAL-GL-1-009',
      ])
      : campaign === 'egyptian'
        ? (req.products = ['MA-PAL-GL-3-001', 'MA-PAL-GL-3-002', 'MA-PAL-GL-3-003', 'MA-PAL-GL-3-004', 'MA-PAL-GL-3-005', 'MA-PAL-GL-3-006'])
        : (req.products = ['EMR-PAL-MASU-003', 'EMR-PAL-MASU-005', 'EMR-PAL-MASU-006', 'EMR-PAL-MASU-009']);
    try {
      const response = await services.getLatestDonors(req);
      setDonors(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  // const getOrders = async _currency => {
  //   let req = {
  //     products: appealProductsCodes,
  //     // limit: 20,
  //     currency: _currency || 'AUD',
  //     start_date: startDateOfAppealCampaign,
  //     end_date: endDateOfAppealCampaign,
  //     // start_date: '2024-02-23'
  //   };
  //   try {
  //     const response = await services.getLatestOrders(req);
  //     setOrders(response.data);
  //     dispatch(setLastOrders(response.data))
  //   } catch (error) {
  //     console.error('error========', error);
  //   }
  // };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempSelectedGiving = [];
    let tempTotalGiving = 0;
    let temp = [];
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
            tempTotalGiving += (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
            tempSelectedGiving.push(item);
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    tempSelectedGiving.length === 1 && setIsMinimized(false);
    setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
    temp = temp.map(item => item).filter(item => item.campaign === campaign)
    setCurrentGivingProducts(temp);
  }
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  const handleCurrency = (currency, tempBasket) => {
    if (tempBasket.products.length && tempBasket.products[0].product) {
      tempBasket.products.map(item => {
        item.currency = currency;
        item.product.currency = currency;
        item.total = item.quantity * item.product[currency];
        item.amount = item.product[currency];
        return item;
      });
      tempBasket.currency = currency;
      HandleLocalStorage(tempBasket);
    } else {
      tempBasket.currency = currency;
      HandleLocalStorage(tempBasket);
    }
  };
  const handleRemoveProduct = (prd) => {
    removeProduct(prd)
    handleSelectedGiving();
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.currency !== selectedCurrency) {
      handleCurrency(selectedCurrency, basketStatesFromLocalStorage);
    }
    if (givingProducts[0]) {
      handleSelectedGiving();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingProducts, selectedCurrency]);
  useEffect(() => {
    // console.log("pathname==",window.location.pathname,isLoaded, selectedCurrencyStore, products)
    setCurrentProduct(null);
    // getOrders(selectedCurrencyStore);
    getDonors(selectedCurrencyStore);
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // console.log("orders==", orders)
    // console.log("pathname==",window.location.pathname,isLoaded, selectedCurrencyStore, products)
    // if (orders.length && products && Object.keys(products).length) {
    //   getAllProducts(orders)
    // }
    if (orders && products && Object.keys(products).length) {
      getAllProducts(orders)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, products, navigate]);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setDonorsReached(entry.isIntersecting)
        setImpactReached(false)
        setOtherAppealsReached(false)
        setLevelGivingReached(false)
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1 // Trigger callback when 10% of the element is visible
      }
    );
    if (donorsRef.current) {
      observer.observe(donorsRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (donorsRef.current) {
        observer.unobserve(donorsRef.current);
      }
    };
  }, [donorsRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setImpactReached(entry.isIntersecting)
        setDonorsReached(false)
        setOtherAppealsReached(false)
        setLevelGivingReached(false)
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1 // Trigger callback when 10% of the element is visible
      }
    );
    if (impactRef.current) {
      observer.observe(impactRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (impactRef.current) {
        observer.unobserve(impactRef.current);
      }
    };
  }, [impactRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setOtherAppealsReached(entry.isIntersecting)
        setImpactReached(false)
        setDonorsReached(false)
        setLevelGivingReached(false)
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1 // Trigger callback when 10% of the element is visible
      }
    );
    if (otherAppealsRef.current) {
      observer.observe(otherAppealsRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (otherAppealsRef.current) {
        observer.unobserve(otherAppealsRef.current);
      }
    };
  }, [otherAppealsRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setLevelGivingReached(entry.isIntersecting)
        setImpactReached(false)
        setDonorsReached(false)
        setOtherAppealsReached(false)
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1 // Trigger callback when 10% of the element is visible
      }
    );
    if (selectGivingRef.current) {
      observer.observe(selectGivingRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (selectGivingRef.current) {
        observer.unobserve(selectGivingRef.current);
      }
    };
  }, [selectGivingRef]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" isAppealPage>
      <Helmet>
        <title>{`${currentProduct ? currentProduct.name : ''} | MATW Project`}</title>
        <meta name="title" content={`${currentProduct ? currentProduct.name : ''} | MATW Project`} />
        <meta name="description" content={`${currentProduct ? currentProduct.description : ''}`} />
        <div itemscope itemtype="http://schema.org/Product">
          <meta itemprop="brand" content="MATW-Project" />
          <meta itemprop="name" content={`${currentProduct ? currentProduct.name : ''} - MATW Project`} />
          <meta itemprop="description" content={`${currentProduct ? currentProduct.description : ''}`} />
          <meta itemprop="productID" content={`${currentProduct ? currentProduct.creator : ''}`} />
          <meta itemprop="url" content={window.location.href} />
          <meta itemprop="image" content={currentProduct ? currentProduct.image_link : ''} />
          <meta itemprop="price" content={currentProduct ? currentProduct.unit_amount : '100'} />
        </div>
      </Helmet>
      <AppealCurrentProduct currentProduct={currentProduct} goal={currentProduct ? currentProduct.goal : goal} campaign={campaign} />
      {/* ------------------- Products Section -------------------- */}
      <div ref={selectGivingRef}>
        <AppealDetailsTabs
          givingProducts={<AppealLevelGiving
            givingProducts={currentgivingProducts}
            title="Select a Giving Level"
            isDetailsPage
            campaign={campaign}
            handleSelectedGivingProps={() => handleSelectedGiving()}
          />}
          donors={<AppealDonors donors={donors} currency={selectedCurrency} usedOnTab />}
          impact={<AppealImpact usedOnTab />}
          appeals={<AppealWidget className="bg-white" appealProducts={appealProducts} usedOnTab title={'Other Appeals'} />}
          lang={lang}
          // impactPostion={impactRef.current.getBoundingClientRect() + (window.scrollY || document.documentElement.scrollTop)}
          impactReached={impactReached}
          donorsReached={donorsReached}
          otherAppealsReached={otherAppealsReached}
          levelGivingReached={levelGivingReached}
          handleImpactSelected={() => {
            window.scrollTo({ top: impactRef.current.offsetTop - 200 })
          }}
          handleDonorsSelected={() => {
            window.scrollTo({ top: donorsRef.current.offsetTop - 200 })
          }}
          handleOtherAppealsSelected={() => {
            window.scrollTo({ top: otherAppealsRef.current.offsetTop - 100 })
          }}
          scrollPosition={scrollPosition}
        />
      </div>
      {/* /* --------------------------------- Youtube -------------------------------- */}
      <section className="w-full md:container md:mx-auto py-4 md:py-8 px-4 md:px-0">
        <div className="relative overflow-hidden flex flex-wrap">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          {/* <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} /> */}
          <div className={`basis-full min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[890px]`}>
            <iframe
              className="video"
              width={'100%'}
              height={'100%'}
              title={'youtube'}
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={campaign === 'palestine'
                ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                : campaign === 'egyptian' ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed' : 'https://youtube.com/embed/DnuL-z8zfSw?autoplay=0&feature=oembed'
              }
            />
          </div>
        </div>
      </section>

      {/* /* ---------------------------------- Text ---------------------------------- */}
      <section className="md:container md:mx-auto px-4 md:px-0 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
          className={`text-2xl md:text-[40px] text-LightBlue ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } mb-5 md:mb-8 md:text-center`}
        >
          {/* <span className="text-DarkerBlue text-4xl font-gotcha mr-4">Adipiscing Elit</span>Lorem ipsum dolor */}
          {campaign === 'palestine' ? 'OUR MATW TEAM HAS NOT STOPPED' : campaign === 'egyptian' ? 'THEY NEED US NOW MORE THAN EVER' : 'HOW IT WORKS'}
        </h1>
        <div
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
            }`}
        >
          {campaign === 'palestine' ? (
            <>
              <p>
                {' '}
                We’re on the ground providing life-saving assistance for displaced families in Palestine as well as those who have fled to neighbouring
                countries seeking refuge. With each day that goes by, the situation becomes more dire and needs become more urgent.
              </p>
              <p>Your support is needed now more than ever.</p>
              <p>
                You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
                family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
                Zakat-applicable.
              </p>
            </>
          ) : campaign === 'egyptian' ? (
            <>
              <p>
                Surviving persecution, these families have done what they can to survive. As Muslims its our duty to support them as much as we can.
                This is why we have active teams on the ground in neighbouring countries like Egypt, ensuring that every refugee family we support
                gets the right kind of help they urgently need.
              </p>
              <p>
                We don’t leave vulnerable families on the streets, we make sure they have enough provisions to start again. We provide things like,
                temporary and permanent housing solutions, furniture like beds, tables, chairs, sofas, mattresses and blankets as well as white goods
                such as ovens, fridges, microwaves and washing machines. We also provide household and hygiene essentials, clothing and food. As well
                as, vocational training, healthcare and psychological support for the families. Finally we also provide a cash stipend for the
                families too.
              </p>
            </>
          ) : (
            <>
              <p>
                As Gazans are unable to leave, two customised vans have been specially designed and fitted with all
                the necessary equipment required
                to fit the new prosthetics. These are called Mobile Amputee Support Units. Once movement is deemed
                safe, they will be deployed from
                the military field hospitals in Jordan to hospitals and neighbourhoods in Gaza to fit the prosthetics.
              </p>
              <p>
                Prosthetics will be fitted with a sensor to measure the use and comfort for amputees.
              </p>
              <p>
                All fittings will be documented and recorded to allow for e-consultations with doctors in Jordan for
                proper follow-up.
              </p>
              <h1 className="text-2xl md:text-[40px] text-LightBlue font-brandingBold pt-3 mb-5 md:mb-8 md:text-center">MATW in Palestine</h1>
              <p className="pt-5">
                With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW
                team were quick to deliver
                urgent essentials to those directly affected by the conflict in Palestine on October 7. Our direct
                response efforts have been focused
                on those who are most vulnerable including orphans, widows, the elderly and families.
              </p>
              <p>
                Our current partnership with The Jordanian Hashemite Charity Organisation, The Egyptian Red Crescent
                and The Egyptian Youth Council enables
                us to further increase our delivery impact and further our reach in areas that are difficult to
                access.
              </p>
              <p>
                To date, our MATW Project team has packed and sent more than 100 trucks carrying essential relief
                items into Palestine. These items have
                included the delivery of emergency medical kits, urgent food packs, rice, oil, fuel, hot meals and
                more to those who have been displaced. &nbsp;
                <a className='underline' href='https://matwproject.org/pdf/Palestine%20Impact%20Report%20-%202023-24.pdf'>Click here to read more about MATW’s Palestine Emergency response.</a>
              </p>
              <p>
                Among our primary focuses now is to ensure that those who are most vulnerable, including orphaned
                children, are not lost to this conflict
                or its consequence of famine.
              </p>
            </>
          )}
        </div>
      </section>
      {/* /* --------------------------------- Impacts -------------------------------- */}
      <div id='impacts' ref={impactRef}>
        <AppealImpact />
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------------- videos section ------------------------------------ */}
      {/* <AppealVideos campaign={campaign} /> */}
      <section className="mt-4 md:mt-12 mb-6">
        {window.location.pathname === '/appeals/palestine-emergency'
          ? <CarouselImages carouselImages={carouselImages} />
          : window.location.pathname === '/appeals/palestine-refugee-resettlement'
            ? <ImagesSection />
            : <CarouselImages carouselImages={carouselImagesLimbs} />
        }
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ------------------------------ Appeal Widget ----------------------------- */}
      <div id="other_appeals" ref={otherAppealsRef}>
        <AppealWidget appealProducts={appealProducts} title={'MATW Appeals'} />
      </div>
      {/* /* --------------------------------- Donors --------------------------------- */}
      <div id="donors" ref={donorsRef}>
        <AppealDonors donors={donors} currency={selectedCurrency} />
      </div>
      {/* {console.log("selecte giving=",selectedGivingProducts)} */}
      {selectedGivingProducts.products.length ? (
        <div className="fixed bottom-0 sm:hidden- [&>div]:w-full z-20- z-[2200000000] shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] w-full left-0 p-2 bg-LighterBlue">
          <div className="flex flex-col gap-1 items-center w-full justify-around mx-auto p-2 rounded-lg bg-white">
            <div className={`flex w-full flex-col border-b border-stone-300 ${isMinimized ? '' : 'mb-1'} pb-1 cursor-pointer`} onClick={handleMinimize}>
              <h2 className={`text-DarkerBlue text-lg md:text-2xl w-full font-bold w-100 flex justify-between`}>
                Your Giving Amount{' '}
                {/* <span className="text-[#f60362] cursor-pointer" onClick={handleMinimize}>
                  ✖
                </span> */}
                <ChevronDownIcon className={`w-4 h-4 cursor-pointer text-stone-600 transition-all duration-300 ${isMinimized ? ' transform rotate-180' : ''}`} />
              </h2>
              <div className="flex items-center">
                <span className="text-DarkerBlue text-sm">
                  {selectedGivingProducts.products.length} items in Cart
                </span>
                <span className="text-LightBlue text-sm cursor-pointer ml-2" onClick={() => navigate(generateLinkWithLang('/checkout', paramLang.lang))}>
                  View Cart
                </span>
              </div>
            </div>
            <div className={`transition-all duration-300 w-full ${isMinimized ? 'h-0' : 'h-auto'} overflow-hidden`}>
              {/* <div className={`transition-all duration-300 overflow-hidden w-full`} style={{ height: `${isMinimized ? '0px' : `${(selectedGivingProducts.products.length > 1 ? selectedGivingProducts.products.length * 32 : selectedGivingProducts.products.length * 36) + 29}px`}` }}> */}
              <div className="flex justify-between items-center flex-col py-1 w-full border-b border-stone-200 max-h-[160px] overflow-y-auto">
                {selectedGivingProducts.products.map((item, index) => {
                  if (showMoreItems) {
                    return (
                      <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                        <div className="text-stone-400 text-xs flex items-center">
                          <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                          <p className="text-stone-400 text-xs">{item.name}</p>
                        </div>
                        <p className="text-xs text-DarkerBlue">
                          {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                        </p>
                      </div>
                    );
                  } else {
                    return index < 2 && (
                      <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                        <div className="text-stone-400 text-xs flex items-center">
                          <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                          <p className="text-stone-400 text-xs">{item.name}</p>
                        </div>
                        <p className="text-xs text-DarkerBlue">
                          {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
              {selectedGivingProducts.products.length > 2 && <div className="flex border-b border-stone-200 pb-2">
                <span className="text-LightBlue text-xs flex items-center cursor-pointer mt-1" onClick={() => setShowMoreItems(!showMoreItems)}>
                  {showMoreItems
                    ? <>Show less items <ChevronDownIcon className={`w-3 h-3 text-stone-500 ml-2`} /></>
                    : <>View more items <ChevronUpIcon className={`w-3 h-3 text-stone-500 ml-2`} /></>
                  }
                </span>
              </div>}
              <div className="flex justify-between items-center border-t border-stone-300 py-1 w-full">
                <p className="text-stone-500 text-xs">Total Amount</p>
                <p className="text-sm font-bold text-DarkerBlue">{ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}</p>
              </div>
            </div>
            <div className="flex items-center mt-1 w-full">
              {/* {!isDetailsPage && <CustomButton
                  onClick={() => handleBack()}
                  title={'Back'}
                  type="button"
                  className={`w-auto !bg-[#00a3da] mr-2`} />} */}
              <CustomButton
                title={
                  <>
                    {`Give `}
                    {ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}
                  </>
                }
                link={'/checkout'}
                className={`${lang === 'ar' ? '' : 'font-["Montserrat"]'} font-bold w-full`}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Layout>
  );
};
// export const startDateOfAppealCampaign = new Date().toISOString().split('T')[0];
// export const startDateOfAppealCampaign = moment().format('YYYY-MM-DD') === '2024-09-04' ? '2024-09-04' : '2024-09-05' ;
export const startDateOfAppealCampaign = '2024-09-05';
export const endDateOfAppealCampaign = '2024-11-05';
export const endDateOfLimbsAppealCampaign = '2024-10-15';
export const appealProductsCodes = [
  'MA-PAL-GL-1-001',
  'MA-PAL-GL-1-002',
  'MA-PAL-GL-1-003',
  'MA-PAL-GL-1-004',
  'MA-PAL-GL-1-005',
  'MA-PAL-GL-1-006',
  'MA-PAL-GL-1-007',
  'MA-PAL-GL-1-008',
  'MA-PAL-GL-1-009',
  // 'MA-PAL-GL-2-001',
  // 'MA-PAL-GL-2-002',
  // 'MA-PAL-GL-2-003',
  // 'MA-PAL-GL-2-004',
  // 'MA-PAL-GL-2-005',
  // 'MA-PAL-GL-2-006',
  // 'MA-PAL-GL-2-007',
  // 'MA-PAL-GL-2-008',
  // 'MA-PAL-GL-2-009',
  'MA-PAL-GL-3-001',
  'MA-PAL-GL-3-002',
  'MA-PAL-GL-3-003',
  'MA-PAL-GL-3-004',
  'MA-PAL-GL-3-005',
  'MA-PAL-GL-3-006',

  'EMR-PAL-MASU-003',
  'EMR-PAL-MASU-005',
  'EMR-PAL-MASU-006',
  'EMR-PAL-MASU-009',
];

export default MATWAppealDetailsLandingPage;