import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Friend from '../../images/ali-banat/friend.jpg';
import signImg from '../../images/others/MATW-Signature.png';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import Policy from '../../images/landings/palestine/policy100.png';
import Mission from '../../images/others/mission.jpg';
import Binocular from '../../images/others/binocular.jpg';
import OurValues from '../../images/others/our-values.jpg';
import Islam from '../../images/others/islam.jpg';
import Confidence from '../../images/others/confidence.jpg';
import Excellence from '../../images/others/execellence.jpg';
import Compassion from '../../images/others/compassion.jpg';
import Unity from '../../images/others/unity.jpg';
import Founder from '../../images/general/founder.jpg';
import OurPromise from '../../images/others/promise.jpg';
import PolicyIcon from '../../images/others/policyIcon.png';
import AnnualIcon from '../../images/others/annualIcon.png';
import FunraiseIcon from '../../images/others/funraiseIcon.png';
import { useNavigate, useParams } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator';

const PurposePage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const navigate = useNavigate();
    const { lang } = useParams(); // Get the language from the URL
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        window.scroll(0, 0);
    }, [])
    /* -------------------------------------------------------------------------- */

    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['aboutUs']['purpose']['title']}`}</title>
                    <meta name="title" content={`${seo['aboutUs']['purpose']['title']}`} />
                    <meta name="description" content={`${seo['aboutUs']['purpose']['description']}`} />
                </Helmet>
            }
            <WidgetHome selectedCurrency={(e) => { }} />

            <section className='bg-white my-16'>
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-10 flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center">
                    <div className='basis-full sm:basis-2/5 justify-center flex'>
                        <img src={Policy} className='w-[160px]' alt="policy" />
                    </div>
                    <div className=''>
                        <div className='text-[#253B7E] font-gotcha text-[50px] text-center sm:text-left'>Our Identity</div>
                        <div className='text-[#14A2DC] text-[24px]'>
                            We are MATW - Muslims Around The World.
                        </div>
                        <div className='text-[#777777] text-[18px] font-brandingMedium'>
                            What started out as a desire to leave a lasting legacy inspired a
                            movement that would change the lives of millions.
                            Confident and raw, we are not your average charity organisation. Driven by our passion and
                            motivated by our faith, we work to support and serve all of Humanity.
                        </div>
                    </div>
                </div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-size-[450px] bg-[#d1e6eb]`} >
                <div className="md:container md:mx-auto mt-5 ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-10 py-8 sm:py-20">

                        <div className=" col-span-12 rounded-2xl bg-white p-6 ">
                            <div className="grid grid-cols-12">
                                <div className=" col-span-12 ">
                                    <div className="flex flex-row  flex-wrap">
                                        <div className="py-2 flex flex-row flex-wrap">
                                            <div className="basis-full lg:basis-2/5 sm:px-2">
                                                <img src={Friend} alt="Ali banat" className='w-full' />
                                            </div>
                                            <div className='basis-full lg:basis-3/6 px-2 text-[#777]'>
                                                <h2 className='text-[#14A2DC] text-2xl my-4'>A message from the CEO (and Ali’s friend)</h2>
                                                <p className='font-brandingMedium text-[18px] leading-5'>
                                                    Assalamu Alaikum,
                                                    <br /><br />
                                                    As we enter our eighth year of operation, I can’t help but feel overwhelmed to see how far Muslims Around The World has come, Alhamdulillah.
                                                    <br /><br />
                                                    What started as a story about being gifted with cancer, has turned into a journey with an entirely new chapter. As leader of this amazing organisation, I feel incredibly privileged to be writing this message on behalf of our MATW team.
                                                    <br /><br />
                                                    It’s because of their hard work, the unwavering support of the global community, and the generosity of our donors that we now enter what we hope will Insha’Allah be another ground-breaking year - 2024.
                                                    <br /><br />
                                                    We began our journey with Ali Banat on the 16th January, 2016. Since our inception, our team has been dedicated to expanding Ali’s legacy and that of our donors, and ensuring our impact.
                                                    <br /><br />
                                                    With each year that passes, we grow more committed and become more determined to reach as many people in need as possible.
                                                    <br /><br />
                                                    It’s important to start each year with renewed intentions and new goals for good. Our goals for MATW are:
                                                    <br /><br />
                                                </p>
                                                <ul className='list-disc font-brandingMedium text-[18px] pl-4 '>
                                                    <li>To maximise the impact of our donors contributions through more sustainable developments.</li>
                                                    <li>To improve our donor experience to strengthen our MATW global community bond. </li>
                                                    <li>To focus on broader communal involvement both at home in Australia, the UK, USA, Canada and abroad in the communities we serve.</li>
                                                    <li>To build equality, equity, and every other thing we do at MATW that are inspired by our values of faith, transparency, excellence, efficiency and sustainability.</li>
                                                </ul>
                                                <br />
                                                <p className='font-brandingMedium text-[18px] leading-5'>
                                                    A special thank you - JazaakumAllahu Kheiran - to our global donor family. We are so grateful to you and are inspired by your commitment to working towards a better world and Akhirah insha’Allah.
                                                </p>
                                                <br />
                                                <p className='font-brandingMedium text-[18px] leading-5'>
                                                    MATW started with Ali. It continues with you.
                                                </p>
                                                <br /><br /><br />
                                                <p className='font-brandingMedium text-[18px] leading-5'>
                                                    Sincerely <br />
                                                    Mahmoud Ismail<br />
                                                    CEO MATW Project <br />
                                                </p>
                                                <img src={signImg} alt="MATW" className='max-w-[200px] mt-5' />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white py-10 sm:py-16 relative overflow-hidden'>
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-10 flex flex-wrap md:flex-nowrap items-center">
                    <div className='bg-[#14A2DC] md:bg-white basis-full md:basis-3/12 text-right pt-10 md:pt-0'>
                        <div className='text-white md:text-[#093686] text-[45px]  text-center sm:text-[50px] font-gotcha leading-[50px] mb-2 relative'>
                            Our Vision and Mission
                        </div>
                        <div className='text-white md:text-[#78716C] text-center sm:text-right font-brandingBold text-[24px] sm:text-[28px] leading-8'>At MATW, we’ve redefined a new kind of charity. </div>
                    </div>
                    <div className='basis-full md:basis-11/12 lg:basis-11/12 relative md:static overflow-hidden '>
                        <div className=' h-[135px] bg-[#14A2DC] absolute w-full'></div>

                        <div className='flex z-10 pb-2 sm:pb-0 relative justify-center px-5 lg:px-10 gap-x-10 gap-y-6 mt-10 flex-wrap sm:flex-nowrap'>
                            <div className='basis-full sm:basis-1/2  text-[18px] font-brandingMedium sm:border-[3px]
                            drop-shadow-[0px_4px_5px_rgba(0,0,0,0.15)] border-[#14A2DC] sm:h-[340px] bg-white rounded-2xl p-4'>
                                <div className='flex justify-center'><img src={Binocular} alt="binocular" /></div>
                                <h2 className='mb-3 text-[#00A3DA] font-brandingBold text-center'>Our Vision</h2>
                                <p className='text-center text-[#777777] font-brandingMedium'>
                                    Through genuine transparency, an unshakable model, and a brand built around impactful,
                                    lasting legacies, we’re building a global community of
                                    generous world-changers who have set out to build for themselves a beautiful Akhirah.
                                </p>
                            </div>
                            <div className='basis-full sm:basis-1/2  text-[18px] font-brandingMedium sm:border-[3px] drop-shadow-[0px_4px_5px_rgba(0,0,0,0.15)] border-[#14A2DC] h-[340px] bg-white rounded-2xl p-4'>
                                <div className='mt-12 sm:mt-3 flex justify-center'><img src={Mission} alt="binocular" /></div>
                                <h2 className=' mb-3 text-[#00A3DA] font-brandingBold text-center'>Our Mission</h2>
                                <p className='text-center text-[#777777] font-brandingMedium'>
                                    Take the legacy of Ali Banat and change the world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='max-w-[1440px] mx-auto'>
                <div className='flex gap-x-8 flex-wrap lg:flex-nowrap'>
                    <div className='basis-full lg:basis-[45%]'>
                        <img src={OurValues} alt="our-values"/>
                    </div>
                    <div className='pt-8 pl-8 basis-full lg:basis-1/2 bg-white text-[#777777] font-brandingMedium'>
                        <div className='text-[#093686] font-gotcha text-[50px]'>
                            Our Values
                        </div>
                        <p className='text-[18px] leading-[18px]'>
                            MATW is committed to the utmost transparency and accountability in our operations. As
                            such, we have implemented robust policies and protocols to safeguard the well-being
                            of our beneficiaries, ensuring
                            that vulnerable populations are not misinformed, endangered, or harmed in any possible way.
                        </p>
                        <div className='flex items-start text-[18px] gap-x-10 mt-6'>
                            <div className='basis-2/12 sm:basis-1/12 lg:basis-1/12'><img src={Islam} alt="islam" /></div>
                            <div className='basis-2/3'>
                                <div className='text-[#14A2DC] font-brandingBold mb-4 leading-4'>Islam</div>
                                <div className='leading-[18px]'>We practice our faith and serve God (Allah) through our positive actions.</div>
                            </div>
                        </div>

                        <div className='flex items-start text-[18px] gap-x-10 mt-6'>
                            <div className='basis-2/12 sm:basis-1/12 lg:basis-1/12'><img src={Compassion} alt="Compassion" /></div>
                            <div className='basis-2/3'>
                                <div className='text-[#14A2DC] font-brandingBold mb-4 leading-4'>Compassion</div>
                                <div className='leading-[18px]'>We care and we help. Our actions speak louder than our words.</div>
                            </div>
                        </div>
                        <div className='flex items-start text-[18px] gap-x-10 mt-6'>
                            <div className='basis-2/12 sm:basis-1/12 lg:basis-1/12'><img src={Excellence} alt="Excellence" /></div>
                            <div className='basis-2/3'>
                                <div className='text-[#14A2DC] font-brandingBold mb-4 leading-4'>Excellence</div>
                                <div className='leading-[18px]'>We strive for the highest quality, so when we deliver, we deliver with impact.</div>
                            </div>
                        </div>
                        <div className='flex items-start text-[18px] gap-x-10 mt-6'>
                            <div className='basis-2/12 sm:basis-1/12 lg:basis-1/12'><img src={Confidence} alt="Confidence" /> </div>
                            <div className='basis-2/3'>
                                <div className='text-[#14A2DC] font-brandingBold mb-4 leading-4'>Confidence</div>
                                <div className='leading-[18px]'>We are bold in our statements because we know what we are doing and we do it well.</div>
                            </div>
                        </div>
                        <div className='flex items-start text-[18px] gap-x-10 mt-6'>
                            <div className='basis-2/12 sm:basis-1/12 lg:basis-1/12'> <img src={Unity} alt="Unity" /></div>
                            <div className='basis-2/3'>
                                <div className='text-[#14A2DC] font-brandingBold mb-4 leading-4'>Unity</div>
                                <div className='leading-[18px]'>We welcome others, and work as a team to change lives. </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='bg-white my-16'>
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2 sm:order-1 basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] font-brandingBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[18px] leading-[18px] font-brandingMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a successful
                            businessman and entrepreneur - everything changed for Ali Banat after a deadly
                            diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/ali-banat', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1 sm:order-2 basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2 sm:order-1 basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] font-brandingBold mb-2' >Our promise to you</div>
                        <div className='text-[#777777] text-[18px] leading-[18px] font-brandingMedium'>
                            Founded by Ali Banat in 2015, Muslims Around The World Project (MATW) is a global
                            humanitarian relief organisation working across 24 countries around the world.
                            Our 100% donation policy ensures that every single donation goes the extra mile
                            in reaching those most in need.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/our-promise', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1 sm:order-2 basis-full sm:basis-1/2'>
                        <img src={OurPromise} className='' alt="policy" />
                    </div>
                </div>
            </section>
            <section className='bg-[#DFEEF2] py-16'>
                <div className="md:container  md:mx-auto px-3 ">
                    <div className='flex flex-wrap md:flex-nowrap gap-x-10 gap-y-10 justify-center'>
                        <div className='bg-[#253B7E] w-[400px] flex min-h-[340px] p-4 rounded-2xl text-center text-white'>
                            <div className='mt-10'>
                                <div className='h-[100px] flex justify-center'><img src={PolicyIcon} alt="" /></div>
                                <div className='font-brandingBold my-3 text-[24px]'>Our 100% donation policy</div>
                                <div className='font-brandingMedium'>
                                    Our policy is a simple $ for $ policy. What we receive from donors for
                                    projects goes directly to the projects. No admin fees or hidden fees are added!
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate(generateLinkWithLang('/annual-reports', lang))} className='bg-[#14A2DC] cursor-pointer    w-[400px] flex min-h-[340px] p-4 rounded-2xl text-center text-white'>
                            <div className='mt-10'>
                                <div className='h-[100px] flex justify-center'><img src={AnnualIcon} alt="" /></div>
                                <div className='font-brandingBold my-3 text-[24px]'>Annual Report</div>
                                <div className='font-brandingMedium'>We are proud to share our achievements and the transformative changes we've brought about.</div>
                            </div>
                        </div>
                        <div onClick={()=>navigate(generateLinkWithLang('/fundraising', lang))} className='bg-[#F60362] cursor-pointer   w-[400px] flex min-h-[340px] p-4 rounded-2xl text-center text-white'>
                            <div className='mt-10'>
                                <div className='h-[100px] flex justify-center'><img src={FunraiseIcon} alt="" /></div>
                                <div className='font-brandingBold my-3 text-[24px]'>Fundraise for us</div>
                                <div className='font-brandingMedium'>Every penny counts on your scales. Click here to get started.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default PurposePage;
