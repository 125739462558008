import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import SelectCurrency from "../../components/general/selectCurrency";
import ReactSelect from "react-select";
import ConvertUSD from "../../utils/convertUSD";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { getProducts } from "../../store/products";
import { formatNumberWithTwoDecimals } from "../../utils/helperFunctions";
import getSymbolFromCurrency from "currency-symbol-map";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const Widget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { className, defaultProduct, mode, donateBtnText, arabic} = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productAmount, setProductAmount] = useState('');
    const [numberOfFasts, setNumberOfFasts] = useState('1');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState(selectedCurrencyStore?selectedCurrencyStore:'AUD');
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [addProduct] = useHandleAddProduct();
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const products = useSelector((state) => getProducts(state, 'Ramadan', 'General'));
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        let widgetProducts = [];

        if (arabic) {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {
                        if (inner_item_product.creator === defaultProduct.creator) {
                            setSelectedProduct(inner_item_product)
                        }

                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') { // Morocco Appeal
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'فلسطين أكثر ما يحتاج', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') { // Afghanistan Earthquake Appeal
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطبية في فلسطين', creator: inner_item_product.creator })
                        }


                        if (inner_item_product.creator === 'MKD-GD-MP001') { // Libya Floods
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حيث يحتاج الأكثر', creator: inner_item_product.creator })
                        }


                        if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'نداء المغرب', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'فيضانات ليبيا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطارئة', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة غذائية', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة المياه', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الأيتام حول العالم', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الصدقة الجارية', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'التعليم', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مشروع النور', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'امشروع البناء', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'توليد الدخل', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-EM-TUR-EQ-01-2023') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في تركيا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في سوريا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'RM24-ZM-013') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'زكاة المال', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'INT-001') { // Keep Orphans Warm Where Most Needed
                            widgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'تنقية الربا', creator: inner_item_product.creator })
                        }
                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });
            tempProductsList = tempProductsList.filter(function ({ creator }) {
                return !this.has(creator) && this.add(creator);
            }, new Set())
        }
        else {
            let p = products.subCategory.products;
            p.forEach(item => {
                if (item.creator === 'RM24-FA-010') {
                    widgetProducts.push(item);
                    tempProductsList.push({ value: item.id, label: item.name, creator: item.creator });
                }
                if (item.creator === 'RM24-FA-011') {

                    widgetProducts.push(item);
                    tempProductsList.push({ value: item.id, label: item.name, creator: item.creator });

                }
                if (defaultProduct) {

                    if (item.creator === defaultProduct.creator) {
                        setSelectedProduct(item);
                        let price = item[`${currentCurrency.toLowerCase()}`];
                        let amount = formatNumberWithTwoDecimals(numberOfFasts * price)

                        setProductAmount(amount);
                    }
                }
            });

        }

        setProductsList(tempProductsList);
        setAllProducts(widgetProducts);
    };
    const handleConvert = (currency, value) => {
        return Math.round(ConvertUSD(currency, value, false))
    }
    const handleSelectedProduct = (product) => {
        allProducts.map(item => item.creator === product.creator && setSelectedProduct(item))

    }
    const handleSelectedItem = (currency, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
        setSelectedPrice(tempSelectedPrice)
    }
    const handleProductAmount = (currency, value) => {
        setProductAmount(handleConvert(currency, value))
    }

    const suggestedPrices = [
        {
            aud: 100,
            usd: 70,
            cad: 90,
            sgd: 90,
            gbp: 60,
            eur: 60,
            myr: 300,
            aed: 240,
            idr: 1002020,
        },
        {
            aud: 250,
            usd: 160,
            cad: 220,
            sgd: 220,
            gbp: 130,
            eur: 150,
            myr: 760,
            aed: 580,
            idr: 2505050,
        },
        {
            aud: 500,
            usd: 320,
            cad: 430,
            sgd: 440,
            gbp: 260,
            eur: 300,
            myr: 1510,
            aed: 1160,
            idr: 5010090,
        },
    ];
    const [selectedPrice, setSelectedPrice] = useState({
        value: suggestedPrices[0][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
        index: 0
    });
    const suggestedRecurringModes = [
        { value: 'one-off', label: arabic ? 'فردي' : 'Single' },
        { value: 'week', label: arabic ? 'أسبوعي' : 'Weekly' },
        { value: 'month', label: arabic ? 'شهري' : 'Monthly' }
    ]
    const [selectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
    const handleAddNewProduct = () => {

        let tempProduct = selectedProduct;
        let customAmount = Number(numberOfFasts) ? Number(numberOfFasts) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurring.value
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
            selectedPrice
                ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index)
                : handleProductAmount(selectedCurrencyStore, productAmount)
        } else {
            setCurrentCurrency('AUD')
            handleSelectedItem('AUD', selectedPrice.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}>
            <div className='text-[#093686] w-full mb-3  leading-[45px] text-center text-[35px] font-bold'>{selectedProduct.name} Calculator</div>
            <div className="basis-full grid grid-cols-12 gap-2">
                <div className="order-1 col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={productsList}
                        className='max-h-[48px] w-full'
                        defaultValue={selectedProduct}
                        isSearchable={false}
                        styles={styles}
                        placeholder='Select Product'
                        onChange={(e, index) => {
                            const item = allProducts.find(i => i.creator === e.creator)
                            const price = item[`${currentCurrency.toLowerCase()}`];
                            if (numberOfFasts) {
                                let amount = formatNumberWithTwoDecimals(numberOfFasts * price)
                                setProductAmount(amount);
                            }
                            handleSelectedProduct(e)
                        }}
                    />
                </div>

                <div className="order-2 col-span-12 min-[500px]:col-span-6 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0" style={styles.shadow}>

                    <input
                        value={numberOfFasts}
                        className="w-full p-2 rounded-lg h-full text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                        type="number"
                        onChange={(e) => {

                            if(e.target.value === '0') return;
                            if (!e.target.value) {
                                setProductAmount('');
                                setNumberOfFasts(e.target.value);
                                return;
                            }

                            let price = selectedProduct[`${currentCurrency.toLowerCase()}`];
                            let amount = formatNumberWithTwoDecimals(e.target.value * price)

                            setProductAmount(amount);
                            setNumberOfFasts(e.target.value);
                        }}
                        placeholder="No. of missed fasts" />

                </div>

                <div className="order-3 col-span-5 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-start justify-center" >
                    <SelectCurrency onChangeCurrency={(e) => {

                        const price = selectedProduct[`${(e.value).toLowerCase()}`];
                        if (numberOfFasts) {

                            let amount = formatNumberWithTwoDecimals(numberOfFasts * price)
                            setProductAmount(amount);
                        }
                        setCurrentCurrency(e.value);

                    }
                    } classNamePrefix="orphan_select" />
                </div>


                <div className="order-5 my-3 sm:my-0 md:order-5 lg:order-4 min-[500px]:order-5 col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0" >

                    <div className="col-span-12 ">
                        <div className={`text-[16px] h-12  leading-5 transition-all duration-200
                         flex justify-start items-center px-2 md:px-1 py-2 md:py-4 min-w-[80px] `}>
                            {selectedProduct?.name && (selectedProduct?.name).toLowerCase() === 'kaffarah' ?
                                `Kaffarah is calculated at feeding 60 people per missed fast
                                ${numberOfFasts ? ` at ${getSymbolFromCurrency(currentCurrency.toLowerCase())}${(productAmount/60).toFixed(2)} per person, totaling ${getSymbolFromCurrency(currentCurrency.toLowerCase())}${productAmount} per missed fast.`:'.'}`

                                :
                                `Fidya is calculated ${numberOfFasts ? ` at ${getSymbolFromCurrency(currentCurrency?.toLowerCase())}${productAmount} `:''}per missed fast.`
                            }
                        </div>
                    </div>


                </div>


                <div className="order-4 md:order-4 lg:order-5 min-[500px]:order-4 col-span-7 min-[500px]:col-span-6 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0" style={styles.shadow}>
                    <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
                    <input
                        value={productAmount}
                        readOnly
                        className="w-full p-2 rounded-lg text-base placeholder:text-[#253B7E] focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                        type="number"
                        onChange={(e) => {
                            setProductAmount(e.target.value);
                            setSelectedPrice(null);
                        }}
                        placeholder="Amount to be paid" />
                </div>
                <div className="order-6 col-span-12 lg:col-span-4 flex justify-center items-center-">
                    <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={handleAddNewProduct}
                    >{donateBtnText ? donateBtnText : 'Quick Donate'}</button>
                </div>
            </div>
        </div>
    );
}

export default Widget;
