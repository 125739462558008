import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../../store/products';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import SeoData from '../../../utils/seo.json';
import Layout from '../../../components/general/layout';
import WidgetHome from '../../../components/widgets/widgetHome';
import LimbsProductBox from './limbsProductBox';


const LimbsOfHopeLandingPage = ({ page = 'general' }) => {
  const products = useSelector(state => selectProducts(state));
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [limbsProducts, setLimbsProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [view, setView] = useState('grid');
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempLimbsProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-PAL-MASU-201') tempLimbsProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-202') tempLimbsProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-203') tempLimbsProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-204') tempLimbsProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-205') tempLimbsProducts[4] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-206') tempLimbsProducts[5] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-207') tempLimbsProducts[6] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-208') tempLimbsProducts[7] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-209') tempLimbsProducts[8] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-210') tempLimbsProducts[9] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-211') tempLimbsProducts[10] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-212') tempLimbsProducts[11] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-213') tempLimbsProducts[12] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-214') tempLimbsProducts[13] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-215') tempLimbsProducts[14] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-216') tempLimbsProducts[15] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-217') tempLimbsProducts[16] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-218') tempLimbsProducts[17] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-219') tempLimbsProducts[18] = inner_item_product;
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempLimbsProducts = tempLimbsProducts.filter(n => n);
    setLimbsProducts(tempLimbsProducts)
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  /* -------------------------------------------------------------------------- */
  return (
    <Layout showRegularImg={false}>
      {seo &&
        <Helmet>
          <title>{`${seo['appeals']['title']}`}</title>
          <meta name="title" content={`${seo['appeals']['title']}`} />
          <meta name="description" content={`${seo['appeals']['description']}`} />
        </Helmet>
      }
      <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="w-full px-4 md:px-2 lg:px-0">
          <h1 className="w-full text-center text-4xl md:text-5xl lg:text-7xl text-[#253b7e] py-2 font-['Gotcha'] mt-6">
            Limbs of Hope
          </h1>
          <div className="flex md:hidden pt-4 mb-4 justify-start items-center border-b border-stone-300">
            <div className={`flex rounded-t-md ${view === 'list' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('list')}>
              List
            </div>
            <div className={`flex rounded-t-md ${view === 'tile' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('tile')}>
              Tile
            </div>
            <div className={`flex rounded-t-md ${view === 'grid' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('grid')}>
              Grid
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4">
              <div className="flex justify-center flex-wrap">
                {limbsProducts.map((prod, index) => {
                  return <div className={`${view === 'list' ? 'basis-full' : view === 'tile' ? 'basis-1/2 px-1' : 'basis-1/3 px-1'} sm:basis-1/3- md:basis-1/3 xl:basis-1/5 py-1 md:p-2`} key={`top10${index}`}>
                    <LimbsProductBox product={prod} currency={selectedCurrency} view={view} showDescription />
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LimbsOfHopeLandingPage;
