import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import SeoData from '../../utils/seo.json';
// import ChangeDocumentTitle from '../../utils/changeDocumentTitle';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import ProductBox from '../../components/general/productBox';

const MakeADonationLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [products, setProducts] = useState({
        name: '',
        products: [0, 0, 0, 0, 0, 0, 0, 0]
    });
    const [isLoading, setIsLoading] = useState(true);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [meta, setMeta] = useState({
        parent: '',
        child: ''
    })

    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getPalestineProducts = async () => {
        let tempPalestineProducts = [];
        try {
            const response = await services.categoryProductsByName('palestine');
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    if (item.name === 'Palestine Appeal') tempPalestineProducts.push(item);
                    if (item.name.trim() === 'Palestine Medical Relief') tempPalestineProducts.push(item);
                }
                return item;
            });
            getMakeDonationProducts(tempPalestineProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getMakeDonationProducts = async (palestin_products) => {
        setIsLoading(true);
        let tempAllProducts = palestin_products;
        let tempProducts = [];
        try {
            let response = await services.categoryProductsByName('make a donation');
            let tempObj = response.data;
            tempObj.products.map(item => {
                if (Number(item.status) === 1) {
                    tempProducts.push(item)
                }
                // if (item.name === 'Emergency Relief') {
                //     item.description = `Emergency relief saves lives. Donate now to help affected countries
                //     with food, shelter, water, and medical care. Your support matters. Make a life-saving
                //     difference today.`
                // }
                // if (item.name === 'Food Aid') {
                //     item.description = `Fight hunger with us: Donate for nutritious meals and food
                //     education, making a difference in those facing food insecurity. Your support matters.`
                // }
                // if (item.name === 'Water Aid') {
                //     item.description = `Transform lives with clean water. Your donation is a lifeline,
                //     offering hope for healthier, brighter futures. Join us in this mission. Donate now.`
                // }
                // if (item.name === 'Orphans Around The World') {
                //     item.description = `Orphans Around the World: A mission of hope for children who've lost parents.
                //      We provide essentials like food, shelter, education, and medical care.
                //      Join us to make a difference in their lives and create a brighter future for all. Donate now.`
                // }
                // if (item.name === 'Sadaqah Jariyah') {
                //     item.description = `Your Sadaqah brings hope and transforms lives. Your generosity sparks
                //     positive change and strengthens faith. Be a beacon of hope today. Donate now.`
                // }
                return item
            })
            let newArray = tempAllProducts.concat(tempProducts);
            tempObj.products = newArray;
            tempObj.tile_image = '/images/landings/donate-now/donate-now.webp';
            setProducts(tempObj);
            setIsLoading(false);
        } catch (error) {
            console.error('error========', error);
            setIsLoading(false);
        }
    };
    const handleParagraph = (text) => {
        let tempText = [];
        if (text) {
            tempText = text.split(".");
            return tempText.map((txt, index) => {
                let temp = txt === "" ? '' : <p key={`p${index}`}>{txt}.<br /></p>
                return temp;
            })
        }
    }
    const camelCaseformat = (val) => {
        let array = val.split('');
        for (let index = 0; index < array.length; index++) {
            if (array[index] === '-') {
                array[index + 1] = array[index + 1].toUpperCase();
            }
        }
        return array.join('').replaceAll('-', '');
    }
    const RawHTML = ({ children, className = "" }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        window.scroll(0, 0);
    }, [])
    useEffect(() => {
        let pathNames = window.location.pathname.split('/');

        console.log(pathNames, '******')
        getPalestineProducts();
        if (pathNames.length > 2) {
            let temp = pathNames[2].split('-');
            temp = temp.join(' ');
            let p = camelCaseformat(pathNames[1])
            let c = camelCaseformat(pathNames[2])
            setMeta({
                ...meta,
                parent: p,
                child: c
            })
            console.log("params", temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout>
            {seo && seo.hasOwnProperty(meta?.parent) && seo[meta?.parent].hasOwnProperty(meta?.child) &&
                <Helmet>
                    <title>{`${seo[meta.parent][meta.child]['title']}`}</title>
                    <meta name="title" content={`${seo[meta.parent][meta.child]['title']}`} />
                    <meta name="description" content={`${seo[meta.parent][meta.child]['description']}`} />
                </Helmet>
            }
            {/* <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]"> */}
            {!isLoading && <img src={products.tile_image} alt={products.name} className='w-full' onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/general/logo-replace.png";
                currentTarget.className = 'max-h-[150px] mx-auto'
            }} />}
            {/* </div> */}

            <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
            <div className="md:container md:mx-auto mt-5">
                <div className="grid grid-cols-12 px-3- md:px-4-">
                    <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
                        {!isLoading ? products.name : <Skeleton height={80} />}
                    </div>
                    <div className="col-start-1 col-span-12 rounded-md">
                        <div className="flex flex-row justify-center flex-wrap">
                            {products.products.map((item, index) => {
                                return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                    <ProductBox product={item} currency={selectedCurrency} />
                                </div>
                            })
                            }
                        </div>
                        <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
                            <h1 className='text-[#00a3da] text-4xl mb-4'>{products ? products.page_title : <Skeleton />}</h1>
                            <p className="text-[#777] text-xl mb-4">
                                {products ? products.page_subtitle : <Skeleton />}
                            </p>
                            <div className="text-[#999] text-md">
                                {products ? products.page_description && products.page_description.includes('<') ? <RawHTML>{products.page_description}</RawHTML> : handleParagraph(products.page_description) : <Skeleton />}
                            </div>
                        </div>
                        <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                            <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                                <iframe className='video'
                                    width={'100%'}
                                    height={'100%'}
                                    title={'youtube'}
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://youtube.com/embed/tC_L9aTE8eI?autoplay=0`}
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default MakeADonationLandingPage;